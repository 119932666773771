import { promptReducer } from './features/prompt/slices/promptSlice';
import { backgroundReducer } from './features/achivement/slice/backgroundSlice';
import { shiftReducer } from './features/shift/slice/shiftSlice';
import { notificationHistoryReducer } from './features/notification/slice/notificationHistorySlice';
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
	achievementReducer,
	challengeReducer,
	dailySparkReducer,
} from "features/achivement/slice";
import authReducer from "features/auth/authSlice";
import {
	employeeListReducer,
	employeeSingleReducer,
	employeeAnalyticsReducer,
	employeeTrackingReducer,
	employeeVerifyTimeReducer,
	employeeVerifyPerMinReducer,
} from "features/employee/slice";
import {
	examProfileListReducer,
	examProfileReducer,
	examResultDetailReducer,
	examResultListReducer,
} from "features/exam/slice";
import {
	feedbackListReducer,
	feedbackRewardReducer,
} from "features/feedback/slice";
import layoutWrapperReducer from "features/layoutWrapper/layoutWrapperSlice";
import { inspectUserReducer, searchUserReducer } from "features/lookup/slice";
import { notificationReducer } from "features/notification/slice";
import {
	reportInfoReducer,
	reportListReducer,
	reportRewardReducer,
} from "features/report/slice";
import {
	bannerCreateReducer,
	bannerListReducer,
	discountCreateReducer,
	productListReducer,
	productSingleReducer,
	transactionListReducer,
} from "features/store/slice";
import { idCheckerReducer } from "features/verification/slice/idCheckerSlice";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import thunk from "redux-thunk";
import { tarotReducer } from 'features/achivement/slice/tarotSlice';
import { lekaFlagReducer } from 'features/leka-flag/slice/flagSlice';

const rootReducer = combineReducers({
	auth: authReducer,
	layoutWrapper: layoutWrapperReducer,
	userSearch: searchUserReducer,
	userInspect: inspectUserReducer,
	employeeList: employeeListReducer,
	employeeSingle: employeeSingleReducer,
	employeeAnalytics: employeeAnalyticsReducer,
	employeeTracking: employeeTrackingReducer,
	employeeVerifyTime: employeeVerifyTimeReducer,
	employeeVerifyPerMin: employeeVerifyPerMinReducer,
	achievementCreate: achievementReducer,
	challengeCreate: challengeReducer,
	transactionList: transactionListReducer,
	productList: productListReducer,
	productSingle: productSingleReducer,
	discountCreate: discountCreateReducer,
	bannerList: bannerListReducer,
	bannerCreate: bannerCreateReducer,
	notification: notificationReducer,
	notificationHistory: notificationHistoryReducer,
	examProfile: examProfileReducer,
	examProfileList: examProfileListReducer,
	examResultDetail: examResultDetailReducer,
	examResultList: examResultListReducer,
	feedbackList: feedbackListReducer,
	feedbackReward: feedbackRewardReducer,
	reportList: reportListReducer,
	reportInfo: reportInfoReducer,
	reportReward: reportRewardReducer,
	idChecker: idCheckerReducer,
	shift: shiftReducer,
	dailySpark: dailySparkReducer,
	background: backgroundReducer,
	tarot: tarotReducer,
	lekaFlag: lekaFlagReducer,
	prompt: promptReducer
});

const persistedReducer = persistReducer(
	{
		key: "fika-admin",
		storage: storageSession,
		whitelist: ["auth"],
	},
	rootReducer,
);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: process.env.NODE_ENV !== "production" ? [thunk, logger] : [thunk],
	devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
