import {
	createSlice,
	PayloadAction,
	ThunkDispatch,
	AnyAction,
} from "@reduxjs/toolkit";
import { RcFile } from "antd/lib/upload";
import { ExamApi } from "api";
import _, { findIndex, map } from "lodash";
import {
	FakeProfile,
	FakeProfileFormData,
	FakeProfileImage,
} from "schema/model";
import { ExamProfileState } from "schema/redux";
import { RootState } from "store";
import { readUploadAsText } from "utils";

const examProfileInitState: ExamProfileState = {
	loading: false,
	error: null,
	profile: {
		profile_images: [],
	},
	success: false,
	images: [],
};

const examProfileSlice = createSlice({
	name: "exam-profile-single",
	initialState: examProfileInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action: PayloadAction<FakeProfile>) => {
			state.loading = false;
			state.error = null;
			state.profile = action.payload;
		},
		success: (state) => {
			state.success = true;
			state.loading = false;
		},
		clearExamProfile: () => examProfileInitState,
	},
});

export const { clearExamProfile } = examProfileSlice.actions;
export const examProfileReducer = examProfileSlice.reducer;

export function addOrRemoveProfileImage(file?: RcFile, pos?: number) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		// let profile = { ...getStore().examProfile.profile };
		let profile = _.cloneDeep(getStore().examProfile.profile);
		// let profile = Array.from(examProfile.profile)
		console.log("Object.isExtensible(profile.profile_imag)",Object.isExtensible(profile.profile_images))
		if (file) {
			const isJpgOrPng =
				file.type === "image/jpeg" || file.type === "image/png";
			if (!isJpgOrPng) return;
			const pImageClone = Array.from(profile.profile_images);
			console.log("pImage", pImageClone)
			const inserted: FakeProfileImage = {
				position: pos,
				fail: false,
				file,
				blob: await readUploadAsText(file),
				set_face: !pImageClone.length ? true : false,
			};
			const _idx = findIndex(
				profile.profile_images,
				(pI) => pI.position === pos,
			);
			console.log("inserted", inserted, _idx)
			if (_idx > -1) {
				profile.profile_images.splice(_idx, 1, inserted);
			} else {
				// profile.profile_images = [...profile.profile_images, inserted];
				profile.profile_images.push(inserted);
			}
		} else {
			const _idx = findIndex(
				profile.profile_images,
				(pI) => pI.position === pos,
			);
			if (_idx > -1) profile.profile_images.splice(_idx, 1);
		}
		// console.log("last Pofile", CProfile)
		dispatch(examProfileSlice.actions.update(profile));
	};
}

export function handleVerifyProfileImage(pos: number, val: 1 | 0) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		// const { profile } = { ...getStore().examProfile };
		let profile = _.cloneDeep(getStore().examProfile.profile);
		const _idx = findIndex(profile.profile_images, (pI) => pI.position === pos);
		if (_idx > -1) {
			profile.profile_images[_idx].fail = val === 1 ? false : true;
			dispatch(examProfileSlice.actions.update(profile));
		}
	};
}

export function handleSwapProfileImage(pos: number) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		// const { profile } = { ...getStore().examProfile };
		let profile = _.cloneDeep(getStore().examProfile.profile);
		const clone = [...profile.profile_images];
		const _idx = findIndex(clone, (c) => c.position === pos);
		const currImg: FakeProfileImage = {
			...clone[_idx],
			set_face: true,
			position: 0,
		};
		const _zeroIdx = findIndex(clone, (c) => c.position === 0);
		if (_zeroIdx > -1) {
			const swapImg: FakeProfileImage = {
				...clone[_zeroIdx],
				set_face: false,
				position: pos,
			};
			profile.profile_images[_zeroIdx] = currImg;
			profile.profile_images[_idx] = swapImg;
			dispatch(examProfileSlice.actions.update(profile));
		} else {
			profile.profile_images[0] = currImg;
			dispatch(examProfileSlice.actions.update(profile));
		}
	};
}

export function setFaceProfileImage(pos: number) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		// const { profile } = { ...getStore().examProfile };
		let profile = _.cloneDeep(getStore().examProfile.profile);
		const clone = [...profile.profile_images];
		const foundIdx = findIndex(clone, (c) => c.position === pos);
		if (foundIdx > -1) {
			clone[foundIdx].set_face = !clone[foundIdx].set_face;
			profile.profile_images = clone;
			dispatch(examProfileSlice.actions.update(profile));
		}
	};
}

export function createOrUpdateProfile(data: FakeProfileFormData) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(examProfileSlice.actions.loading());
		try {
			// const { profile: profileState } = { ...getStore().examProfile };
			let profileState = _.cloneDeep(getStore().examProfile.profile);

			if (!profileState.profile_images.length) {
				dispatch(
					examProfileSlice.actions.error("Vui lòng chọn ít nhất 1 ảnh profile"),
				);
				return;
			}
			const profile: FakeProfile = {
				name: {
					name: data["name.name"],
					fail: data["name.fail"] === 1 ? false : true,
				},
				bio: {
					name: data["bio.name"],
					fail: data["bio.fail"] === 1 ? false : true,
				},
				occupation: {
					name: data["occupation.name"],
					fail: data["occupation.fail"] === 1 ? false : true,
				},
				status: data["status"],
			};
			if (profileState.profile_id) {
				try {
					const resp = await ExamApi.updateProfile(
						profileState.profile_id,
						profile,
					);
					const api = map(profileState.profile_images, async (i) => {
						return await ExamApi.updateProfileImage({
							...i,
							profile_id: profileState.profile_id,
						});
					});
					Promise.all(api)
						.then(() => { })
						.catch((_) => { });
					const validationImg = profileState.validation_photo;
					validationImg.fail = data["validation.fail"] === 1 ? false : true;
					validationImg.type = data["validation.type"];
					await ExamApi.updateProfileValidationImage({
						profile_id: profileState.profile_id,
						...validationImg,
					});
					dispatch(examProfileSlice.actions.update(resp));
					dispatch(examProfileSlice.actions.success());
				} catch (e) {
					dispatch(examProfileSlice.actions.error(e.message));
					throw e;
				}
			} else {
				const { profile_id } = await ExamApi.createProfile(profile);
				if (profile_id) {
					const validationImage = data["validation.image"]["file"];
					try {
						await ExamApi.uploadValidationImage({
							profile_id,
							type: data["validation.type"],
							file: validationImage,
							fail: data["validation.fail"] === 1 ? false : true,
						});
					} catch (_) { }
					const profileImages = profileState.profile_images;
					if (profileImages.length) {
						const sorted = profileImages.sort(
							(a, b) => a.position - b.position,
						);
						const api = map(
							sorted,
							async ({ fail, file, set_face }, idx) =>
								await ExamApi.uploadProfileImage({
									position: idx,
									profile_id,
									fail,
									file,
									set_face,
								}),
						);
						Promise.all(api)
							.then((_) => { })
							.catch((_) => { });
					}
				}
				// dispatch(fetchFakeUsers());
				dispatch(examProfileSlice.actions.success());
			}
		} catch (e) {
			dispatch(examProfileSlice.actions.error(e.message));
		}
	};
}

export function loadProfileForEdit(id?: string) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(examProfileSlice.actions.loading());
		try {
			const resp = await ExamApi.getProfileById(id);
			dispatch(examProfileSlice.actions.update(resp));
		} catch (e) {
			dispatch(examProfileSlice.actions.error(e.message));
		}
	};
}
