import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { LogsApi } from "api";
import { cloneDeep, forEach } from "lodash";
import { LogRequestQuery } from "schema/api";
import { LogWarningItem } from "schema/model";
import { EmployeeTrackingState } from "schema/redux";
import { RootState } from "store";

const initTrackingState: EmployeeTrackingState = {
	loading: false,
	error: null,
	results: [],
	warnings: [],
	query: { page: 1, limit: 30 },
	total: 0,
};

const trackingSlice = createSlice({
	name: "employee-tracking",
	initialState: initTrackingState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		query: (state, action) => {
			state.query = action.payload;
		},
		updateNotes: (state, action) => {
			state.warnings = action.payload;
		},
		updateData: (state, action) => {
			state.loading = false;
			state.results = action.payload.items;
			state.total = action.payload.total;
		},
		clearEmployeeTracking: () => initTrackingState,
	},
});

export const { clearEmployeeTracking } = trackingSlice.actions;
export const employeeTrackingReducer = trackingSlice.reducer;

export function loadEmployeeTracking(query: LogRequestQuery) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(trackingSlice.actions.loading());
		let copiedQuery = cloneDeep(getStore().employeeTracking.query) ?? {};
		if (query) copiedQuery = { ...copiedQuery, ...query };
		forEach(copiedQuery, (v, k) => {
			if (!v) delete copiedQuery[k];
		});
		dispatch(trackingSlice.actions.query(copiedQuery));
		try {
			const response = await LogsApi.getLogs(copiedQuery);
			dispatch(trackingSlice.actions.updateData(response));
			// if (copiedQuery['email']) forEach(response['items'], (i: LogRawItem) => i.user_id = copiedQuery['email'])
			// if (response)
			// else {
			// 	const logs = { ...getStore().employeeLogs };
			// 	dispatch(
			// 		logsTrackingUpdate({ total: logs.total, items: logs.results })
			// 	);
			// }
		} catch (err) {
			dispatch(trackingSlice.actions.error(err.message));
		}
	};
}

export function loadOperatorNotes(query: LogRequestQuery) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(trackingSlice.actions.loading());
		try {
			const notes = await LogsApi.getNotes(query);
			dispatch(trackingSlice.actions.updateNotes(notes));
		} catch (err) {
			dispatch(trackingSlice.actions.error(err.message));
		}
	};
}

export function sendNote(note: LogWarningItem) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(trackingSlice.actions.loading());
		try {
			const response = await LogsApi.sendNote(note);
			let copiedNotes = [...getStore().employeeTracking.warnings];
			copiedNotes.unshift(response);
			dispatch(trackingSlice.actions.updateNotes(copiedNotes));
		} catch (err) {
			dispatch(trackingSlice.actions.error(err.message));
		}
	};
}
