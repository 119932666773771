import { createSlice } from "@reduxjs/toolkit";
import { UserApi } from "api";
import { isEmpty } from "lodash";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ValidIdStatus } from "schema/general";
import { IdCheckerState } from "schema/redux";

const initState: IdCheckerState = {
	loading: false,
	error: null,
	user: null,
};

const idCheckerSlice = createSlice({
	name: "id-checker",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateUser: (state, action) => {
			state.loading = false;
			state.user = action.payload;
		},
		clearIdChecker: () => initState,
	},
});

export const { clearIdChecker } = idCheckerSlice.actions;
export const idCheckerReducer = idCheckerSlice.reducer;

export function fetchBannedUser() {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(idCheckerSlice.actions.loading());
		try {
			const resp = await UserApi.getBanned();
			if (!isEmpty(resp)) {
				dispatch(idCheckerSlice.actions.updateUser(resp));
			} else dispatch(idCheckerSlice.actions.updateUser(null));
		} catch (err) {
			dispatch(idCheckerSlice.actions.error(err.message));
		}
	};
}

export function updateBannedUserStatus(
	slug: string,
	valid_id_status: ValidIdStatus,
	status: string,
) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(idCheckerSlice.actions.loading());
		try {
			const resp = await UserApi.updateBannedStatus(
				slug,
				valid_id_status,
				status,
			);
			if (resp.message === "Ok") {
				// dispatch(bannedUserUpdate())
				dispatch(fetchBannedUser());
			}
		} catch (err) {
			dispatch(idCheckerSlice.actions.error(err.message));
		}
	};
}
