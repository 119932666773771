import {
	createSlice,
	PayloadAction,
	ThunkDispatch,
	AnyAction,
} from "@reduxjs/toolkit";
import { ExamApi } from "api";
import { ExamResult } from "schema/model";
import { ExamResultListState } from "schema/redux";
import { RootState } from "store";

const examResultListInitState: ExamResultListState = {
	loading: false,
	error: null,
	results: [],
	searchResults: [],
	query: {
		page: 1,
		limit: 30,
		total: 0,
	},
};

const examResultListSlice = createSlice({
	name: "exam-result-list",
	initialState: examResultListInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateResults: (state, action: PayloadAction<ExamResult[]>) => {
			state.loading = false;
			state.error = null;
			state.results = action.payload;
		},
		updateQuery: (state, action) => {
			state.query = action.payload;
		},
		updateSearch: (state, action) => {
			state.searchResults = action.payload;
		},
		clearExamResultList: () => examResultListInitState,
	},
});

export const { clearExamResultList } = examResultListSlice.actions;
export const examResultListReducer = examResultListSlice.reducer;

export function fetchResultList() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(examResultListSlice.actions.loading());
		try {
			const query = { ...getStore().examResultList.query };
			const { total, items } = await ExamApi.getExamResultList(query);
			dispatch(examResultListSlice.actions.updateResults(items));
			dispatch(examResultListSlice.actions.updateQuery({ ...query, total }));
		} catch (e) {
			dispatch(examResultListSlice.actions.error(e.message));
		}
	};
}

export function updateResultQuery(q) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		const clone = { ...getStore().examResultList.query };
		if (q) {
			const query = { ...clone, ...q };
			dispatch(examResultListSlice.actions.updateQuery(query));
			dispatch(fetchResultList());
		}
	};
}
export function searchResultList(query) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(examResultListSlice.actions.loading());
		try {
			if (query) {
				let q = {
					limit: 30,
					page: 1,
					...query,
				};
				const { total, items } = await ExamApi.getExamResultList(query);
				dispatch(examResultListSlice.actions.updateResults(items));
				dispatch(examResultListSlice.actions.updateQuery({ ...q, total }));
			}
		} catch (e) {
			dispatch(examResultListSlice.actions.error(e.message));
		}
	};
}
