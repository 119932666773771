import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { EmployeeApi } from "api";
import { Operator } from "schema/model";
import { EmployeeListState, EmployeeState } from "schema/redux";
import { RootState } from "store";

const listInitState: EmployeeListState = {
	loading: false,
	error: null,
	employees: [],
};

const singleInitState: EmployeeState = {
	loading: false,
	error: null,
	employee: null,
	saved: false,
	success: false,
};

const listSlice = createSlice({
	name: "employee-list",
	initialState: listInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action) => {
			state.loading = false;
			state.error = null;
			state.employees = action.payload;
		},
		clearEmployeeList: () => listInitState,
	},
});

const singleSlice = createSlice({
	name: "employee-single",
	initialState: singleInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.success = false;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action) => {
			state.loading = false;
			state.error = null;
			state.employee = action.payload;
			state.success = true;
		},
		clearEmployeeSingle: () => singleInitState,
	},
});

export const { clearEmployeeList } = listSlice.actions;
export const { clearEmployeeSingle } = singleSlice.actions;

export const employeeListReducer = listSlice.reducer;
export const employeeSingleReducer = singleSlice.reducer;

export function getListEmployee() {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(listSlice.actions.loading());
		try {
			const employees = await EmployeeApi.getEmployees();
			dispatch(listSlice.actions.update(employees));
		} catch (err) {
			dispatch(listSlice.actions.error(err.message));
		}
	};
}
export function deactiveEmployee(username: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(listSlice.actions.loading());
		try {
			const _ = await EmployeeApi.deactiveEmployee(username);
			let employees = [...getStore().employeeList?.employees] ?? [];
			employees = employees.filter((e) => e.username != username);
			dispatch(listSlice.actions.update(employees));
		} catch (err) {
			dispatch(listSlice.actions.error(err.message));
		}
	};
}

export function createEmployee(
	data: {
		password: string;
	} & Operator,
) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(singleSlice.actions.loading());
		try {
			const employee = await EmployeeApi.create(data);
			const employees = [...getStore().employeeList?.employees] ?? [];
			employees.push(employee);
			dispatch(singleSlice.actions.update(employee));
			dispatch(listSlice.actions.update(employees));
		} catch (err) {
			dispatch(singleSlice.actions.error(err.message));
		}
	};
}
