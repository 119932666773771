import { RcFile } from "antd/lib/upload";
import request from "api/request";
import { HOST } from "config/api";
import { forEach } from "lodash";
import qs from "qs";
import { ApiListResponse, DailySparkRequestQuery } from "schema/api";
import { BackgroundModel } from "schema/model";
import { _toBaseUrl } from "utils";

const Endpoint = _toBaseUrl(HOST) + "/daily-sparks";

export const getAllBackgrounds = (query: DailySparkRequestQuery) => {
	const { limit, page } = query;
	const qr = qs.stringify(
		{ limit, page },
		{
			indices: false,
			skipNulls: true,
			arrayFormat: "index",
		},
	);
	return request<ApiListResponse<BackgroundModel>>(
		"GET",
		Endpoint + "/backgrounds?" + qr,
	);
};

export const postBackground = (data: BackgroundModel) => {
	const clone = { ...data };

	// formData.append('file', `${clone.file}` as any);
	const formData = new FormData();
	forEach<BackgroundModel>(clone, (v, k) => {
		if (k === "file") formData.append("file", v as RcFile);
		else formData.append(k, `${v}`);
	});

	return request<ApiListResponse<BackgroundModel>>(
		"POST",
		Endpoint + `/background`, //?font=${clone.font}&color=${clone.color}
		formData,
	);
};

export const deleteBackground = (key: string) => {
	return request<BackgroundModel>(
		"DELETE",
		Endpoint + "/background?id=" + key,
	);
};
