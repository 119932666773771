import { HOST } from "config/api";
import {
	ApiListResponse,
	ReportRequestQuery,
	GeneralApiResponse,
} from "schema/api";
import { ReportGroup, ReportRewardRequest } from "schema/model";
import request from "../request";

const ReportEndpoint = HOST + "/reports";

export const getReports = (query: ReportRequestQuery) =>
	request<ApiListResponse<ReportGroup>>("GET", ReportEndpoint, query);

export const readReport = (report_ids: string[]) =>
	request<GeneralApiResponse>("PUT", ReportEndpoint + "/read", report_ids);

export const updateReward = (reward: ReportRewardRequest) =>
	request<GeneralApiResponse>("PUT", ReportEndpoint + "/reward", reward);
