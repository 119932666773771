import { createSlice } from "@reduxjs/toolkit";

const layoutWrapperSlice = createSlice({
	name: "layout-wrapper",
	initialState: { collapsed: false, title: "" },
	reducers: {
		expand: (state) => {
			state.collapsed = false;
		},
		collapse: (state) => {
			state.collapsed = true;
		},
		toggle: (state) => {
			state.collapsed = !state.collapsed;
		},
		updateTitle: (state, action) => {
			state.title = action.payload;
		},
	},
});

export const { expand, collapse, toggle, updateTitle } =
	layoutWrapperSlice.actions;
export default layoutWrapperSlice.reducer;
