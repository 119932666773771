import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import locale from "antd/lib/locale/vi_VN";

import { store, persistor } from "store";
import { history, AppRouter } from "routers";

import "./App.css";
import { ConfigProvider } from "antd";

function App() {
	console.log("======== Current Env: " + process.env.REACT_APP_FLAVOR);
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConfigProvider locale={locale}>
					<Router history={history}>
						<AppRouter />
					</Router>
				</ConfigProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
