import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { LogsApi } from "api";
import { EmployeeAnalyticsState } from "schema/redux";
import { RootState } from "store";
import { processAnalyticsData } from "./tableDataProcessing";

const initAnalyticsState: EmployeeAnalyticsState = {
	loading: false,
	error: null,
	chartData: [],
	tableData: [],
	usernames: [],
};
const analyticsSlice = createSlice({
	name: "employee-analytics",
	initialState: initAnalyticsState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		filter: (state, action) => {
			state.loading = false;
			state.usernames = action.payload;
		},
		update: (state, action) => {
			state.loading = false;
			state.chartData = action.payload[0];
			state.tableData = action.payload[1];
		},
		clearEmployeeAnalytics: () => initAnalyticsState,
	},
});

export const { clearEmployeeAnalytics } = analyticsSlice.actions;

export const employeeAnalyticsReducer = analyticsSlice.reducer;

export function addUsername(username: string) {
	return addOrRemoveUsername(username, true);
}

export function removeUsername(username: string) {
	return addOrRemoveUsername(username, false);
}

function addOrRemoveUsername(username: string, add: boolean) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		let copiedArray = [...getStore().employeeAnalytics.usernames];
		if (add) {
			const existed = copiedArray.indexOf(username);
			if (existed == -1) {
				copiedArray.push(username);
			}
		} else {
			// remove
			copiedArray = copiedArray.filter((u) => u != username);
		}
		dispatch(analyticsSlice.actions.filter(copiedArray));
	};
}
export function loadEmployeeAnalytics({
	from_date,
	to_date,
	refresh = false,
}: {
	from_date: number;
	to_date: number;
	refresh?: boolean;
}) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(analyticsSlice.actions.loading());
		try {
			const {
				chartData: initChartData,
				tableData: initTableData,
				usernames,
			} = getStore().employeeAnalytics;
			const fetchingUsernames = refresh
				? usernames
				: usernames.filter(
						(u) => initChartData.findIndex((c) => c.category == u) == -1,
				  );
			console.log(fetchingUsernames);
			const { chartData, tableData } = await processAnalyticsData({
				metrics: fetchingUsernames,
				initTableData: refresh ? [] : initTableData,
				initChartData: refresh ? [] : initChartData,
				fetchData: async (username) => {
					return await LogsApi.getSummaryLogs({ from_date, to_date, username });
				},
			});
			dispatch(analyticsSlice.actions.update([chartData, tableData]));
		} catch (err) {
			dispatch(analyticsSlice.actions.error(err.message));
		}
	};
}
