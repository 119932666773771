import {
	createSlice,
	PayloadAction,
	ThunkDispatch,
	AnyAction,
} from "@reduxjs/toolkit";
import { ReportApi } from "api";
import { ApiListResponse, ReportRequestQuery } from "schema/api";
import { Report, ReportGroup } from "schema/model";
import { ReportListState } from "schema/redux";
import { RootState } from "store";

const listInitState: ReportListState = {
	loading: false,
	error: null,
	total: 0,
	reports: [],
	query: { limit: 10, page: 1, type: "unread" },
};

const reportListSlice = createSlice({
	name: "report-list",
	initialState: listInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateData: (
			state,
			action: PayloadAction<ApiListResponse<ReportGroup>>,
		) => {
			state.loading = false;
			state.reports = action.payload.items;
			state.total = action.payload.total;
		},
		updateQuery: (state, action) => {
			state.query = action.payload;
		},
		removeReportOfUser: (state, action) => {
			state.reports = state.reports.filter(
				(f) => f.reported_id != action.payload,
			);
			state.loading = false;
		},
		clearReportList: () => listInitState,
	},
});

export const { clearReportList, removeReportOfUser } = reportListSlice.actions;
export const reportListReducer = reportListSlice.reducer;

export function fetchReports(query: ReportRequestQuery = {}) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		let q = {...query, limit: 15}
		dispatch(reportListSlice.actions.loading());
		let cloneQuery = { ...getStore().reportList.query, ...query };
		dispatch(reportListSlice.actions.updateQuery(cloneQuery));
		try {
			const reportReponse = await ReportApi.getReports(cloneQuery);
			dispatch(reportListSlice.actions.updateData(reportReponse));
		} catch (err) {
			dispatch(reportListSlice.actions.error(err.message));
		}
	};
}

export function dismissReport(report: ReportGroup) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(reportListSlice.actions.loading());
		try {
			// fetch api
			const response = await ReportApi.readReport(
				report.reports?.map((r) => r.report_id) ?? [],
			);
			dispatch(reportListSlice.actions.removeReportOfUser(report.reported_id));
		} catch (err) {
			dispatch(reportListSlice.actions.error(err.message));
		}
	};
}
