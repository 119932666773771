import { RcFile } from "antd/lib/upload";
import request from "api/request";
import { HOST } from "config/api";
import { GeneralApiResponse } from "schema/api";
import { Achievement, DailyChallenge } from "schema/model";
import { _toBaseUrl } from "utils";

const Endpoint = _toBaseUrl(HOST);

export const getAllChallenge = () =>
	request<DailyChallenge[]>("GET", Endpoint + "/task");

export const createChallenge = (data: DailyChallenge) =>
	request<{
		task_id?: string;
	}>("POST", Endpoint + "/task/", data);

export const uploadChallengeImage = (task_id: string, file: RcFile) => {
	const form = new FormData();
	form.append("file_obj", file);
	return request<GeneralApiResponse>(
		"POST",
		Endpoint + "/task/task-image?task_id=" + task_id,
		form,
	);
};
export const createAchievement = (data: Achievement) =>
	request<{ achievement_id: string }>("POST", Endpoint + "/achievement/", data);

export const uploadAchievementImage = (
	achievement_id: string,
	file: RcFile,
) => {
	const form = new FormData();
	form.append("file_obj", file);
	return request<GeneralApiResponse>(
		"POST",
		Endpoint + "/achievement/achieve-image?achieve_id=" + achievement_id,
		form,
	);
};
