import { createSlice, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { AchievementApi } from "api";
import { forEach } from "lodash";
import { DailyChallenge } from "schema/model";
import { BaseCreateState } from "schema/redux";

const initState: BaseCreateState = {
	loading: false,
	error: null,
	success: false,
};

const challengeSlice = createSlice({
	name: "challenge-new",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
			state.success = false;
		},
		success: (state) => {
			state.loading = false;
			state.error = null;
			state.success = true;
		},
		clearChallenge: () => initState,
	},
});

export const { clearChallenge } = challengeSlice.actions;
export const challengeReducer = challengeSlice.reducer;

/**
 * This action will create task
 * It takes form data and transform to Taks interface
 * @param data: any
 * @returns {void}
 */

export function createChallenge(data: any) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(challengeSlice.actions.loading());
		try {
			const body: DailyChallenge = {};
			forEach(data, (v, k) => {
				let key = k.split("-");
				if (key.length > 1) {
					body[key[1]] = { ...body[key[1]], [key[0]]: v };
				} else if (k !== "file_obj") {
					body[k] = v;
				}
			});
			const { task_id } = await AchievementApi.createChallenge(body);
			if (task_id) {
				const file = data?.file_obj?.file;
				if (file) await AchievementApi.uploadChallengeImage(task_id, file);
			}
			dispatch(challengeSlice.actions.success());
		} catch (e) {
			dispatch(challengeSlice.actions.error(e.message));
		}
	};
}
