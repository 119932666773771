import { LEKA_HOST } from "config/api";
import qs from "qs";
import { ReportPostQuery } from "schema/api";
import { PromptItem } from "schema/model";
import request from "../request";

const UserEndPoint = LEKA_HOST + "/admin";

export const getPrompts = (query: ReportPostQuery) => {
	const q = qs.stringify(
		{ ...query },
		{
			indices: false,
			skipNulls: true,
			arrayFormat: "index",
		},
	);
	return request<{items: PromptItem[], total: number}>(
		"GET",
		UserEndPoint + "/prompts?" + q,
	);
};

export const postPrompt = (query: PromptItem) => {
	
	return request<any>("POST", UserEndPoint + "/prompt", {prompt: query})
}

export const getPromptById = (id: string) => {
	return request<PromptItem>("GET", UserEndPoint + "/prompt/" + id)
}

export const putPrompt = (id: string, data: PromptItem) => {
	return request<any>("PUT", UserEndPoint + "/prompt/" + id, {prompt: data})
}
