import { HOST } from "config/api";
import { AppUser, AppUserApiData, AppUserFull } from "schema/model";
import { ApiListResponse, AppUserSearchQuery } from "schema/api";
import request from "../request";
import { UserStatus } from "schema/general";

const UserEndPoint = HOST + "/user";

export const get = () => request<AppUser>("GET", UserEndPoint);

export const getPendingCount = () =>
	request<number>("GET", UserEndPoint + "/pending");

export const process = (user: AppUserApiData) =>
	request<AppUser>("PUT", UserEndPoint, { user });

export const deleteValidateImage = (user_id: string) =>
	request<any>("PATCH", HOST + "/delete-validate-image/" + user_id);

export const search = (query: AppUserSearchQuery) =>
	request<ApiListResponse<AppUserFull>>("GET", UserEndPoint + "s", query);

export const update = (userId: string, status: UserStatus) =>
	request<any>("PUT", UserEndPoint + "s/" + userId, { user: { status } });

export const getBanned = () => request<AppUser>("GET", `${HOST}/banned_user`);

export const updateBannedStatus = (
	slug: string,
	valid_id_status: string,
	status: string,
) =>
	request<any>("PUT", `${HOST}/banned_user`, {
		user: { user_id: slug, valid_id_status, status },
	});

export const getById = (user_id: string) =>
	request<AppUser>("GET", UserEndPoint + "s/" + user_id);
