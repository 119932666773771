import request from "api/request";
import { HOST } from "config/api";
import { OrderRequestQuery } from "schema/api";
import { Order } from "schema/model";
import { _toBaseUrl } from "utils";

const OrderEndpoint = _toBaseUrl(HOST) + "/orders";

export const getAll = (query: OrderRequestQuery) =>
	request<Order[]>("GET", OrderEndpoint + "/retry-orders", query);

export const getAllSuccess = (query: OrderRequestQuery) =>
	request<Order[]>("GET", OrderEndpoint + "/retry-orders-success", query);

export const verifyOrderById = (transaction_id: string) =>
	request("POST", OrderEndpoint + "/" + transaction_id);
