import { createSlice, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { TransactionApi } from "api";
import { OrderRequestQuery } from "schema/api";
import { OrderType } from "schema/general";
import { Order } from "schema/model";
import { TransactionListState } from "schema/redux";
import { RootState } from "store";

const transactionListInitState: TransactionListState = {
	loading: false,
	error: null,
	orders: [],
	query: {
		page_chunk_size: 10,
		page_number: 0,
	},
};

const transactionListSlice = createSlice({
	name: "transaction-list",
	initialState: transactionListInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateData: (state, action) => {
			state.loading = false;
			state.orders = action.payload;
		},
		updateQuery: (state, action) => {
			state.query = action.payload;
		},
		clearTransactionList: () => transactionListInitState,
	},
});

export const { clearTransactionList } = transactionListSlice.actions;
export const transactionListReducer = transactionListSlice.reducer;

export function fetchOrders(query: OrderRequestQuery, type?: OrderType) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(transactionListSlice.actions.loading());
		let cloneQuery = { ...getStore().transactionList.query };
		if (query) cloneQuery = { ...cloneQuery, ...query };
		try {
			const resp: Order[] =
				type === "all"
					? await TransactionApi.getAll(cloneQuery)
					: await TransactionApi.getAllSuccess(query);
			dispatch(transactionListSlice.actions.updateData(resp));
		} catch (e) {
			dispatch(transactionListSlice.actions.error(e.message));
		}
	};
}

export function verifyOrderById(transaction_id: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(transactionListSlice.actions.loading());
		let query = { ...getStore().transactionList.query };
		try {
			await TransactionApi.verifyOrderById(transaction_id);
			dispatch(fetchOrders(query, "all"));
		} catch (e) {
			dispatch(transactionListSlice.actions.error(e.message));
		}
	};
}
