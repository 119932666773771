import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { LekaFlagApi } from "api";
import { ReportPostQuery } from "schema/api";
import { LekaFlagState } from "schema/redux";
import { RootState } from "store";

const initialState: LekaFlagState = {
	reports: [],
	loading: false,
	total: 0,
	query: { page_chunk_size: 10, page_number: 1 },
	report: null,
};

const lekaFlagSlice = createSlice({
	initialState: initialState,
	name: "leka-flag",
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		error: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},
		updateQuery: (state, { payload }) => {
			state.query = payload;
		},
		updateReportList: (state, { payload }) => {
			state.reports = payload.posts;
			state.total = payload.total_posts;
			state.loading = false;
		},
		updateReportView: (state, { payload }) => {
			state.report = payload;
		},
	},
});

export const lekaFlagReducer = lekaFlagSlice.reducer;
export const { loading, error, updateReportView, updateQuery } =
	lekaFlagSlice.actions;

export function getReportPosts(query: ReportPostQuery) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		const { query } = getStore().lekaFlag;
		try {
			const res = await LekaFlagApi.getReportPosts(query);
			if (res) {
				dispatch(lekaFlagSlice.actions.updateReportList({ ...res }));
			}
		} catch (err) {
			dispatch(error(err.message));
		}
	};
}

export function banUser(userId: string, postId: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		try {
			const { reports, total } = getStore().lekaFlag;
			const res = await LekaFlagApi.banUser(userId);
			if (res) {
				dispatch(lekaFlagSlice.actions.updateReportList({ ...res }));
				const newPosts = reports.filter((x) => x.post_id !== postId);

				dispatch(
					lekaFlagSlice.actions.updateReportList({ posts: newPosts, total }),
				);
			}
		} catch (err) {
			dispatch(error(err.message));
		}
	};
}

export function resetFlag(postId: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		try {
			const res = await LekaFlagApi.resetFlag(postId);
			if (res) {
				const { reports, total } = getStore().lekaFlag;
				const newPosts = reports.filter((x) => x.post_id !== postId);

				dispatch(
					lekaFlagSlice.actions.updateReportList({ posts: newPosts, total }),
				);
			}
		} catch (err) {
			dispatch(error(err.message));
		}
	};
}

export function removePost(postId: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		try {
			const res = await LekaFlagApi.deletePost(postId);
			if (res) {
				const { reports, total } = getStore().lekaFlag;
				const newPosts = reports.filter((x) => x.post_id !== postId);

				dispatch(
					lekaFlagSlice.actions.updateReportList({ posts: newPosts, total }),
				);
			}
		} catch (err) {
			dispatch(error(err.message));
		}
	};
}
