import { createSlice, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { BannerApi } from "api";
import { BannerListState } from "schema/redux";

const bannerListInitState: BannerListState = {
	loading: false,
	error: null,
	banners: [],
};

const bannerListSlice = createSlice({
	name: "banner-list",
	initialState: bannerListInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action) => {
			state.banners = action.payload;
			state.loading = false;
		},
		clearBannerList: () => bannerListInitState,
	},
});

export const { clearBannerList } = bannerListSlice.actions;
export const bannerListReducer = bannerListSlice.reducer;

export function fetchBanners() {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(bannerListSlice.actions.loading());
		try {
			const data = await BannerApi.getAllBanners();
			dispatch(bannerListSlice.actions.update(data));
		} catch (e) {
			dispatch(bannerListSlice.actions.error(e.message));
		}
	};
}

export function deleteBanner(id: string) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(bannerListSlice.actions.loading());
		try {
			const data = await BannerApi.deleteBanner(id);
			dispatch(bannerListSlice.actions.update(data));
		} catch (e) {
			dispatch(bannerListSlice.actions.error(e.message));
		}
	};
}
