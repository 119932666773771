import { ApiListResponse } from './../../../schema/api';
import { AnyAction, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { ShiftApi } from 'api';
import { ShiftHistoryRequest } from 'schema/api';
import { ShiftHistotyData, ShiftState } from 'schema/redux';
import { RootState } from 'store';

const initState: ShiftState = {
	loading: false,
	error: null,
	success: false,
	shifts: [],
	total: 0,
	query: {
		limit: 10,
		operator: "superadmin",
		page: 1,
	}
};

const shiftSlice = createSlice({
	name: "shift",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = false;
			state.error = null;
		},
		error: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		updateData: (state, { payload }: PayloadAction<ApiListResponse<ShiftHistotyData>>) => {
			state.shifts = payload.items;
			state.loading = false;
			state.total = payload.total;
		},
		updateQuery: (state, { payload }: PayloadAction<ShiftHistoryRequest>) => {
			state.query = payload;
		},
		clearShiftHistory: () => initState,
	}
});

export const shiftReducer = shiftSlice.reducer;

export function fetchShiftHistory() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState
	) => {
		try {
			const query: ShiftHistoryRequest = {
				operator: getStore().shift.query.operator,
				limit: 10,
				page: 1,
			}
			console.log("a")
			const resp = await ShiftApi.getHistory(query);
			dispatch(shiftSlice.actions.updateData(resp));
		} catch (err) {
			dispatch(shiftSlice.actions.error(err.message));
		}

	}
}

export function updateShiftQuery(page: number, limit: number) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		console.log("pageNumber", page, limit);
		const query = {...getStore().shift.query, page: page, limit: limit};
		dispatch(shiftSlice.actions.updateQuery(query));
		try {
			const resp = await ShiftApi.getHistory(query);
			dispatch(shiftSlice.actions.updateData(resp));
		} catch (err) {
			dispatch(shiftSlice.actions.error(err.message));
		}
	}
}

export function updateOperator(operator: string){
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		const query = {...getStore().shift.query, operator: operator};
		dispatch(shiftSlice.actions.updateQuery(query));
		try {
			const resp = await ShiftApi.getHistory(query);
			dispatch(shiftSlice.actions.updateData(resp));
		} catch (err) {
			dispatch(shiftSlice.actions.error(err.message));
		}
	}
}