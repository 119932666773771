import request from "api/request";
import { SUPER_HOST } from "config/api";
import qs from "qs";
import { ApiListResponse, AudienceNotification, GeneralApiResponse, NotificationRequest, NotificationRequestQuery } from "schema/api";
import { NotificationHistorySchedule } from "schema/model";

const NotificationEndpoint = SUPER_HOST + "schedule/";

export const send = (data: NotificationRequest) => {
	const { age_range = null, genders = null, include_ids = null, user_status = null, devices = null, location = null, body } = data;
	const query = qs.stringify(
		{ age_range, genders, include_ids, user_status, devices, location },
		{
			indices: false,
			skipNulls: true,
			arrayFormat: "index",
		},
	);
	console.log('query ===============>', query)
	return request<GeneralApiResponse>(
		"POST",
		NotificationEndpoint + `fcm/send?` + query,
		body,
	);
};

export const getSchedules = (query: NotificationRequestQuery) => {
	return request<ApiListResponse<NotificationHistorySchedule>>(
		"GET",
		NotificationEndpoint + `show_schedules`, query
	)
}

//just test
export const saveAudienceNotification = (data: AudienceNotification) => {

	return request<GeneralApiResponse>(
		"POST",
		NotificationEndpoint,
		data,
	)
}
