/* eslint-disable react-hooks/exhaustive-deps */
import { Layout } from "antd";
import { Login, Logout } from "features/auth";
import { checkUser } from "features/auth/authSlice";
import { AppHeader, SideBar } from "features/layoutWrapper";
import { map } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import { UserRole } from "schema/general";
import { RootState } from "store";
import "./AppRouter.css";
import Route from "./Route";
import routes from "./routes";

const _renderRoute = (role: UserRole): JSX.Element[] => {
	return map(
		routes.filter(({ roles }) => roles?.indexOf(role) >= 0),
		({ component, ...rest }, idx) => (
			<Route key={idx} component={component} {...rest} />
		),
	);
};

export default function AppRouters() {
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		dispatch(checkUser());
	}, []);

	return (
		<Switch>
			<Route path="/dashboard" isPrivate>
				<Layout className="layout-sider" style={{flex: "0 0 0"}} hasSider>
					<SideBar />
					<Layout className="site-main-wrapper">
						<AppHeader />
						<Layout.Content className="site-content-wrapper">
							<Switch>{user?.role ? _renderRoute(user.role) : null}</Switch>
						</Layout.Content>
					</Layout>
				</Layout>
			</Route>
			<Route component={Logout} path="/logout" isPrivate />
			<Route component={Login} path="/" exact isAuth />
		</Switch>
	);
}
