import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { UserApi } from "api";
import { AppUserSearchQuery } from "schema/api";
import { UserStatus } from "schema/general";
import { SearchUserState } from "schema/redux";
import { RootState } from "store";

const initialState: SearchUserState = {
	loading: false,
	error: null,
	results: [],
	query: { page: 1, limit: 10 },
	total: 0,
};

export const searchUserSlice = createSlice({
	name: "user-search",
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		search: (state, action) => {
			state.query = action.payload;
		},
		update: (state, action) => {
			state.loading = false;
			state.results = action.payload.items;
			state.total = action.payload.total;
		},
		clearSearchUsers: () => initialState,
	},
});

const { loading, error, search, update } = searchUserSlice.actions;

export const { clearSearchUsers } = searchUserSlice.actions;
export const searchUserReducer = searchUserSlice.reducer;

export function searchUser(query: AppUserSearchQuery) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(loading());
		let copiedQuery = getStore().userSearch.query ?? {};
		if (query) copiedQuery = { ...copiedQuery, ...query };
		for (const key in copiedQuery) {
			if (copiedQuery[key] == null || copiedQuery[key] === "")
				delete copiedQuery[key];
		}
		dispatch(search(copiedQuery));
		try {
			const response = await UserApi.search(copiedQuery);
			dispatch(update(response));
		} catch (err) {
			dispatch(error(err.message));
		}
	};
}

export function updateUser(userId: string, status: UserStatus) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(loading());
		try {
			const _ = await UserApi.update(userId, status);
			const response = await UserApi.search(getStore().userSearch.query);
			dispatch(update(response));
		} catch (err) {
			dispatch(error(err.message));
		}
	};
}
