import { findIndex, forEach, map } from "lodash";
import {
	ExamProfileInfo,
	ExamResult,
	ExamResultChartItem,
	FakeProfile,
} from "schema/model";

export function processExamResult(data: ExamResult): ExamResultChartItem[][] {
	if (data) {
		const chart: any = [];
		const { answers, questions } = data;
		forEach(questions, (q: FakeProfile, idx: number) => {
			let items: ExamResultChartItem[] = [];
			if (q.bio) {
				items.push({
					name: "bio",
					answer:
						answers[idx].infos[
							findIndex(
								answers[idx].infos,
								(i: ExamProfileInfo) => i.name === "bio",
							)
						].fail,
					question: q.bio.fail,
				});
			}
			if (q.occupation) {
				items.push({
					name: "occupation",
					answer:
						answers[idx].infos[
							findIndex(
								answers[idx].infos,
								(i: ExamProfileInfo) => i.name === "occupation",
							)
						].fail,
					question: q.occupation.fail,
				});
			}
			if (q.name) {
				items.push({
					name: "name",
					answer:
						answers[idx].infos[
							findIndex(
								answers[idx].infos,
								(i: ExamProfileInfo) => i.name === "name",
							)
						].fail,
					question: q.name.fail,
				});
			}
			if (q.validation_photo) {
				items.push({
					name: "verification",
					answer: answers[idx].verification_photo,
					question: q.validation_photo.fail,
					url: q.validation_photo.url,
				});
			}
			if (q.profile_images) {
				items.push(
					...map(q.profile_images, (i) => ({
						name: "profile_images",
						url: i.url,
						answer:
							answers[idx].profile_images[
								findIndex(
									answers[idx].profile_images,
									(_i: ExamProfileInfo) => _i.name === i.key,
								)
							].fail,
						question: i.fail,
						q_face: i.set_face,
						a_face:
							answers[idx].profile_images[
								findIndex(
									answers[idx].profile_images,
									(_i: ExamProfileInfo) => _i.name === i.key,
								)
							].set_face,
					})),
				);
			}
			if (q.status) {
				items.push({
					name: "status",
					answer: answers[idx].status,
					question: q.status,
				});
			}
			chart.push(items);
		});
		return chart;
	}
}
