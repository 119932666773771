import { RcFile } from "antd/lib/upload";
import request from "api/request";
import { HOST } from "config/api";
import { GeneralApiResponse } from "schema/api";
import { Discount } from "schema/model";
import { _toBaseUrl } from "utils";

const DiscountEndpoint = _toBaseUrl(HOST) + "/discounts";

export const getAll = () => request<Discount[]>("GET", DiscountEndpoint);

export const createDiscount = (data: Discount) =>
	request<{ discount_id: string }>("POST", DiscountEndpoint, data);

export const uploadImage = (
	discount_id: string,
	product_code: string,
	file_obj: RcFile,
) => {
	const formData = new FormData();
	formData.append("file_obj", file_obj);
	return request<GeneralApiResponse>(
		"POST",
		DiscountEndpoint +
			"/image" +
			`?discount_id=${discount_id}&product_code=${product_code}`,
		formData,
	);
};
