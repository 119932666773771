import Loadable from "react-loadable";
import {
	AuditOutlined,
	BugOutlined,
	ClockCircleOutlined,
	CreditCardOutlined,
	FieldStringOutlined,
	FileDoneOutlined,
	FileImageOutlined,
	IdcardOutlined,
	InboxOutlined,
	NotificationOutlined,
	PercentageOutlined,
	PictureOutlined,
	ReadOutlined,
	SafetyOutlined,
	ScheduleOutlined,
	SearchOutlined,
	ShopOutlined,
	TeamOutlined,
	TrophyOutlined,
} from "@ant-design/icons";

import { IRoute } from "schema/route";
import { Loader } from "uikit";

const SearchAll = Loadable({
	loader: () => import("features/lookup/pages/SearchAll"),
	loading: Loader,
});

const IdChecker = Loadable({
	loader: () => import("features/verification/pages/IdChecker"),
	loading: Loader,
});

const EmployeeAnalytics = Loadable({
	loader: () => import("features/employee/pages/EmployeeAnalytics"),
	loading: Loader,
});

const EmployeeTracking = Loadable({
	loader: () => import("features/employee/pages/EmployeeTracking"),
	loading: Loader,
});

const EmployeeEdit = Loadable({
	loader: () => import("features/employee/pages/EmployeeEdit"),
	loading: Loader,
});

const EmployeeList = Loadable({
	loader: () => import("features/employee/pages/EmployeeList"),
	loading: Loader,
});

const EmployeeVerifyTime = Loadable({
	loader: () => import("features/employee/pages/VerificationTimeTracking"),
	loading: Loader,
});

const DailySpark = Loadable({
	loader: () => import("features/achivement/pages/DailySpark"),
	loading: Loader,
});

const DailySparkBackground = Loadable({
	loader: () => import("features/achivement/pages/Background"),
	loading: Loader,
});

const Tarot = Loadable({
	loader: () => import("features/achivement/pages/Tarot"),
	loading: Loader,
});

const DailyChallenge = Loadable({
	loader: () => import("features/achivement/pages/DailyChallenge"),
	loading: Loader,
});

const Achievement = Loadable({
	loader: () => import("features/achivement/pages/Achievement"),
	loading: Loader,
});

const Transaction = Loadable({
	loader: () => import("features/store/pages/Transaction"),
	loading: Loader,
});

const Store = Loadable({
	loader: () => import("features/store/pages/Store"),
	loading: Loader,
});

const ProductAdd = Loadable({
	loader: () => import("features/store/pages/ProductAdd"),
	loading: Loader,
});

const DiscountList = Loadable({
	loader: () => import("features/store/pages/DiscountList"),
	loading: Loader,
});

const DiscountAdd = Loadable({
	loader: () => import("features/store/pages/DiscountAdd"),
	loading: Loader,
});

const BannerList = Loadable({
	loader: () => import("features/store/pages/BannerList"),
	loading: Loader,
});

const BannerAdd = Loadable({
	loader: () => import("features/store/pages/BannerAdd"),
	loading: Loader,
});

const Notification = Loadable({
	loader: () => import("features/notification/pages/Notification"),
	loading: Loader,
});

const NotificationHistory = Loadable({
	loader: () => import("features/notification/pages/NotificationHistory"),
	loading: Loader,
});

const ExamList = Loadable({
	loader: () => import("features/exam/pages/ExamList"),
	loading: Loader,
});

const ExamProfile = Loadable({
	loader: () => import("features/exam/pages/ExamSingle"),
	loading: Loader,
});

const ResultList = Loadable({
	loader: () => import("features/exam/pages/ResultList"),
	loading: Loader,
});

const ResultDetail = Loadable({
	loader: () => import("features/exam/pages/ResultDetail"),
	loading: Loader,
});

const FeedbackList = Loadable({
	loader: () => import("features/feedback/FeedbackList"),
	loading: Loader,
});

const ReportList = Loadable({
	loader: () => import("features/report/ReportList"),
	loading: Loader,
});

const Shift = Loadable({
	loader: () => import("features/shift/pages/Shift"),
	loading: Loader,
});

const LekaFlag = Loadable({
	loader: () => import("features/leka-flag/pages/LekaFlag"),
	loading: Loader,
})

const PromptPage = Loadable({
	loader: () => import("features/prompt/pages/PromptPage"),
	loading: Loader,
})

const CreatePromptPage = Loadable({
	loader: () => import("features/prompt/pages/CreatePrompt"),
	loading: Loader,
})

const routes: IRoute[] = [
	{
		component: LekaFlag,
		path: "/dashboard/leka-flag",
		roles: ["super-admin", "admin"],
		title: "Leka Fla",
		menu: { title: "Leka Flag", icon: TeamOutlined, header: false },
	},
	{
		component: PromptPage,
		path: "/dashboard/prompts",
		exact: true,
		roles: ["super-admin", "admin"],
		title: "Prompt",
		menu: { title: "Prompt", icon: TeamOutlined, header: false },
	},
	{
		component: CreatePromptPage,
		path: "/dashboard/prompts/create",
		exact: true,
		roles: ["super-admin", "admin"],
		title: "Create New Prompt",
	},
	{
		component: CreatePromptPage,
		path: "/dashboard/prompts/edit/:id",
		exact: true,
		roles: ["super-admin", "admin"],
		title: "Edit Prompt",
	},
	{
		component: SearchAll,
		path: "/dashboard/search",
		roles: ["admin", "super-admin"],
		title: "Search Users",
		menu: {
			title: "Search",
			icon: SearchOutlined,
			header: true
		},
	},
	{
		component: IdChecker,
		path: "/dashboard/idchecker",
		roles: ["super-admin", "admin"],
		title: "ID Checker",
		menu: { title: "ID Checker", icon: IdcardOutlined },
	},
	{
		component: Notification,
		path: "/dashboard/notification/create",
		exact: true,
		roles: ["super-admin"],
		title: "New Notification",
	},
	{
		component: NotificationHistory,
		path: "/dashboard/notification/history",
		exact: true,
		roles: ["super-admin"],
		title: "Notification",
		menu: { title: "Notification", icon: NotificationOutlined },
	},
	{
		component: FeedbackList,
		path: "/dashboard/feedbacks",
		roles: ["super-admin", "admin"],
		title: "Feedbacks",
		menu: { title: "Feedbacks", icon: InboxOutlined },
	},
	{
		component: ReportList,
		path: "/dashboard/reports",
		roles: ["super-admin", "admin"],
		title: "Reports",
		menu: { title: "Reports", icon: SafetyOutlined },
	},
	{
		component: DailyChallenge,
		path: "/dashboard/achievement/task",
		roles: ["admin", "super-admin"],
		title: "Daily Challenge",
		menu: { title: "Daily Challenge", icon: ScheduleOutlined, header: true },
	},
	{
		component: DailySpark,
		path: "/dashboard/achievement/daily-spark-quote",
		roles: ["admin", "super-admin"],
		title: "Daily Spark Quote",
		menu: { title: "Daily Spark Quote", icon: FieldStringOutlined },
	},
	{
		component: Tarot,
		path: "/dashboard/achievement/tarot",
		roles: ["admin", "super-admin"],
		title: "Tarot Card",
		menu: { title: "Tarot Card", icon: CreditCardOutlined },
	},
	{
		component: DailySparkBackground,
		path: "/dashboard/achievement/daily-spark-backgorund",
		roles: ["admin", "super-admin"],
		title: "Daily Spark Background",
		menu: { title: "Background", icon: FileImageOutlined },
	},
	{
		component: Achievement,
		path: "/dashboard/achievement",
		roles: ["admin", "super-admin"],
		title: "Achievements",
		menu: { title: "Achievements", icon: TrophyOutlined },
	},
	{
		component: Store,
		path: "/dashboard/store",
		roles: ["super-admin"],
		exact: true,
		title: "Fika Store",
		menu: { title: "Fika Store", icon: ShopOutlined, header: true },
	},
	{
		component: ProductAdd,
		path: "/dashboard/store/add",
		roles: ["super-admin"],
		title: "Add Combo",
	},
	{
		component: DiscountList,
		path: "/dashboard/store/discount",
		roles: ["super-admin"],
		exact: true,
		title: "All Discounts",
		menu: { title: "Discounts", icon: PercentageOutlined },
	},
	{
		component: DiscountAdd,
		path: "/dashboard/store/discount/add",
		roles: ["super-admin"],
		title: "Add Discount",
	},
	{
		component: BannerList,
		path: "/dashboard/store/banner",
		roles: ["super-admin"],
		exact: true,
		title: "All Banners",
		menu: { title: "Banners", icon: PictureOutlined },
	},
	{
		component: BannerAdd,
		path: "/dashboard/store/banner/add",
		roles: ["super-admin"],
		title: "Add Banner",
	},
	{
		component: Transaction,
		path: "/dashboard/transaction",
		roles: ["super-admin"],
		title: "Transactions",
		menu: { title: "Transaction", icon: CreditCardOutlined },
	},
	{
		component: EmployeeAnalytics,
		path: "/dashboard/employee/analytics",
		roles: ["admin", "super-admin"],
		title: "Operator Report",
		menu: {
			title: "Operator Report",
			icon: AuditOutlined,
			header: true,
		},
	},
	{
		component: EmployeeTracking,
		path: "/dashboard/employee/tracking",
		roles: ["admin", "super-admin"],
		title: "Operator Tracking",
		menu: { title: "Operator Tracking", icon: BugOutlined },
	},
	{
		component: EmployeeVerifyTime,
		path: "/dashboard/employee/verification",
		roles: ["admin", "super-admin"],
		title: "Verify Time Tracking",
		menu: { title: "Verify Time Tracking", icon: ClockCircleOutlined },
	},
	{
		component: Shift,
		path: "/dashboard/shift",
		roles: ["super-admin", "admin", "operator"],
		title: "Shift",
		menu: { title: "Shift", icon: ClockCircleOutlined },
	},
	{
		component: ExamList,
		path: "/dashboard/exam",
		roles: ["super-admin", "admin"],
		exact: true,
		title: "All Exams",
		menu: { title: "Exams Dashboard", icon: ReadOutlined, header: true },
	},
	{
		component: ExamProfile,
		path: "/dashboard/exam/profile",
		roles: ["super-admin", "admin"],
		exact: true,
		title: "Create Exam Profile",
	},
	{
		component: ExamProfile,
		path: "/dashboard/exam/profile/:id",
		roles: ["super-admin", "admin"],
		title: "Edit Exam Profile",
	},
	{
		component: ResultList,
		path: "/dashboard/exam/result",
		roles: ["super-admin", "admin", "operator"],
		exact: true,
		title: "Exam Results",
		menu: { title: "Exam Resuls", icon: FileDoneOutlined },
	},
	{
		component: ResultDetail,
		path: "/dashboard/exam/result/:id",
		roles: ["super-admin", "admin"],
		title: "Result Details",
	},
	{
		component: EmployeeEdit,
		path: "/dashboard/employee/create",
		roles: ["super-admin"],
		title: "New Employee",
	},
	{
		component: EmployeeList,
		path: "/dashboard/employee",
		roles: ["super-admin"],
		title: "Team",
		menu: { title: "Team", icon: TeamOutlined, header: true },
	},
];

export default routes;
