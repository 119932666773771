import {
	createSlice,
	PayloadAction,
	ThunkDispatch,
	AnyAction,
} from "@reduxjs/toolkit";
import { ExamApi } from "api";
import { ExamResult, ExamResultChartItem } from "schema/model";
import { ExamResultDetailState } from "schema/redux";
import { processExamResult } from "./dataProcessing";

const examResultDetailInitState: ExamResultDetailState = {
	loading: false,
	error: null,
	result: null,
	chartData: [],
};

const examResultDetailSlice = createSlice({
	name: "exam-result-single",
	initialState: examResultDetailInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateResult: (state, action: PayloadAction<ExamResult>) => {
			state.loading = false;
			state.error = null;
			state.result = action.payload;
		},
		updateChart: (state, action: PayloadAction<ExamResultChartItem[][]>) => {
			state.chartData = action.payload;
		},
		clearExamResultDetail: () => examResultDetailInitState,
	},
});

export const { clearExamResultDetail } = examResultDetailSlice.actions;
export const examResultDetailReducer = examResultDetailSlice.reducer;

export function fetchResultById(exam_id: string) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(examResultDetailSlice.actions.loading());
		try {
			const resp = await ExamApi.getExamResult(exam_id);
			dispatch(examResultDetailSlice.actions.updateResult(resp));
			dispatch(
				examResultDetailSlice.actions.updateChart(processExamResult(resp)),
			);
		} catch (e) {
			dispatch(examResultDetailSlice.actions.error(e.message));
		}
	};
}
