import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { UserApi } from "api";
import { InspectUserState } from "schema/redux";
import { RootState } from "store";

const initState: InspectUserState = {
	loading: false,
	user: null,
	error: null,
	selected: "",
};

const inspectSlice = createSlice({
	name: "user-inspect",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action) => {
			state.loading = false;
			state.user = action.payload;
		},
		selectUser: (state, action) => {
			state.loading = false;
			state.selected = action.payload;
		},
		clearInspectUser: () => initState,
	},
});

export const { selectUser: selectUserForInspect, clearInspectUser } =
	inspectSlice.actions;

export const inspectUserReducer = inspectSlice.reducer;

export function fetchUserForInspect() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(inspectSlice.actions.loading());
		try {
			const selected = getStore().userInspect.selected;
			if (selected) {
				const resp = await UserApi.getById(selected);
				dispatch(inspectSlice.actions.update(resp));
			}
		} catch (e) {
			dispatch(inspectSlice.actions.error(e.message));
		}
	};
}
