import { MenuOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import "./AppHeader.css";
import { toggle } from "./layoutWrapperSlice";


export default function AppHeader() {
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.auth?.user);
	const { title } = useSelector((state: RootState) => state.layoutWrapper);

	const handleSidebarExpand = () => {
		dispatch(toggle());
	};

	return (
		<Layout.Header className="site-header-wrapper">
			<MenuOutlined
				style={{ color: "white", fontSize: 25 }}
				onClick={handleSidebarExpand}
				className="site-header-wrapper__icon"
			/>
			<div className="header-page-name">{title}</div>
			{user && <div className="user-welcome">Welcome, {user.name}</div>}
		</Layout.Header>
	);
}
