import { Col, message, Row } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import LoginForm from "../views/LoginForm";
import "./Login.css";

export default function Login() {
	const history = useHistory();
	const auth = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		if (auth.user !== null) {
			history.push("/dashboard");
		}
	}, [auth.user]);

	useEffect(() => {
		if (auth.error !== null && auth.error !== "") {
			message.error(auth.error);
		}
	}, [auth.error]);

	return (
		<Row className="login-wrapper">
			<Col span={24}>
				<div className="login-right-wrapper">
					<LoginForm />
				</div>
			</Col>
		</Row>
	);
}
