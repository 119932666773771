import {
	createSlice,
	PayloadAction,
	ThunkDispatch,
	AnyAction,
} from "@reduxjs/toolkit";
import { ExamApi } from "api";
import { FakeProfile } from "schema/model";
import { ExamProfileListState } from "schema/redux";

const examProfileListInitState: ExamProfileListState = {
	loading: false,
	error: null,
	profiles: [],
};

const examProfileListSlice = createSlice({
	name: "exam-profile-list",
	initialState: examProfileListInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action: PayloadAction<FakeProfile[]>) => {
			state.loading = false;
			state.error = null;
			state.profiles = action.payload;
		},
		success: (state) => {
			state.loading = false;
		},
		clearExamProfileList: () => examProfileListInitState,
	},
});

export const { clearExamProfileList } = examProfileListSlice.actions;
export const examProfileListReducer = examProfileListSlice.reducer;

export function fetchFakeUsers() {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(examProfileListSlice.actions.loading());
		try {
			const resp = await ExamApi.getAllProfiles();
			dispatch(examProfileListSlice.actions.update(resp));
		} catch (e) {
			dispatch(examProfileListSlice.actions.error(e.message));
		}
	};
}

export function deleteProfile(profile_id: string) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		try {
			await ExamApi.deleteProfile(profile_id);
			dispatch(fetchFakeUsers());
		} catch (e) {
			dispatch(examProfileListSlice.actions.error(e.message));
		}
	};
}
