import {
	createSlice,
	ThunkDispatch,
	AnyAction,
	PayloadAction,
} from "@reduxjs/toolkit";
import { UserApi } from "api";
import { ReportGroup } from "schema/model";
import { ReportInfoState } from "schema/redux";

const initState: ReportInfoState = {
	loading: false,
	error: null,
	report: null,
	user: null,
	popupVisible: null,
};

const reportInfoSlice = createSlice({
	name: "report-info",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateReport: (state, action: PayloadAction<ReportGroup>) => {
			state.report = action.payload;
		},
		updateUser: (state, action) => {
			state.loading = false;
			state.popupVisible = true;
			state.user = action.payload;
		},
		closePopUp: (state) => {
			state.popupVisible = false;
		},
		success: (state) => {
			state.success = true;
		},
		clearReportInfo: () => initState,
	},
});  

export const { clearReportInfo, closePopUp } = reportInfoSlice.actions;
export const reportInfoReducer = reportInfoSlice.reducer;

export function viewReportContent(report: ReportGroup) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(reportInfoSlice.actions.loading());
		dispatch(reportInfoSlice.actions.updateReport(report));
		try {
			const user = await UserApi.getById(report.reported_id);
			dispatch(reportInfoSlice.actions.updateUser(user));
		} catch (err) {
			dispatch(reportInfoSlice.actions.error(err.message));
		}
	};
}

