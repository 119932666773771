import {
	createSlice,
	PayloadAction,
	ThunkDispatch,
	AnyAction,
} from "@reduxjs/toolkit";
import { BannerApi, ProductApi } from "api";
import moment from "moment";
import { BannerApiRequest } from "schema/api";
import { Product } from "schema/model";
import { BannerCreateState } from "schema/redux";

const bannerCreateInitState: BannerCreateState = {
	loading: false,
	error: null,
	success: false,
	products: [],
};

const bannerCreateSlice = createSlice({
	name: "banner-create",
	initialState: bannerCreateInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		success: (state) => {
			state.success = true;
			state.loading = false;
		},
		updateProducts: (state, action: PayloadAction<Product[]>) => {
			state.products = action.payload;
			state.loading = false;
		},
		clearBannerCreate: () => bannerCreateInitState,
	},
});

export const { clearBannerCreate } = bannerCreateSlice.actions;
export const bannerCreateReducer = bannerCreateSlice.reducer;

export function fetchCombosForBanner() {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(bannerCreateSlice.actions.loading());
		try {
			const data = await ProductApi.get("combo");
			dispatch(bannerCreateSlice.actions.updateProducts(data));
		} catch (err) {
			dispatch(bannerCreateSlice.actions.error(err.message));
		}
	};
}

export function createBanner(data: any) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(bannerCreateSlice.actions.loading());
		try {
			data["expiration_date"] = data["expiration_date"].utc();
			data["start_date"] = data["start_date"].utc();
			data["file"] = data["file"].file;
			const body: BannerApiRequest = {
				expiration_date: moment(data["expiration_date"]).utc(),
				start_date: moment(data["start_date"]).utc(),
				file: data["file"].file,
				...data,
			};
			await BannerApi.createBanner(body);
			dispatch(bannerCreateSlice.actions.success());
		} catch (e) {
			dispatch(bannerCreateSlice.actions.error(e.message));
		}
	};
}
