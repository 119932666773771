import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { LogsApi } from "api";
import { map } from "lodash";
import moment from "moment";
import { OperatorRequestQuery } from "schema/api";
import { AnalyticsChartData } from "schema/general";
import { EmployeeVerifyTimeState } from "schema/redux";
import { processVerificationTime } from "./tableDataProcessing";

const initState: EmployeeVerifyTimeState = {
	loading: false,
	chartData: [],
	error: null,
};

const employeeVerifyTimeSlice = createSlice({
	name: "employee-verify-time",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action) => {
			state.loading = false;
			state.chartData = action.payload;
		},
		clearEmployeeVerifyTime: () => initState,
	},
});

const employeeVerifyPerMinSlice = createSlice({
	name: "employee-verify-per-minute",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action) => {
			state.loading = false;
			state.chartData = action.payload;
		},
		clearEmployeeVerifyPerMin: () => initState,
	},
});

export const { clearEmployeeVerifyTime } = employeeVerifyTimeSlice.actions;
export const employeeVerifyTimeReducer = employeeVerifyTimeSlice.reducer;

export const { clearEmployeeVerifyPerMin } = employeeVerifyPerMinSlice.actions;
export const employeeVerifyPerMinReducer = employeeVerifyPerMinSlice.reducer;

export function loadVerificationTime({
	operator,
	from_date,
	to_date,
	unit,
}: OperatorRequestQuery) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(employeeVerifyTimeSlice.actions.loading());
		try {
			const data = await processVerificationTime({
				unit,
				fetchData: async () => {
					return await LogsApi.getVerificationTime({
						from_date,
						to_date,
						operator,
					});
				},
			});
			dispatch(employeeVerifyTimeSlice.actions.update(data));
		} catch (e) {
			dispatch(employeeVerifyTimeSlice.actions.error(e.message));
		}
	};
}

export const loadTrackingPerMin = ({
	operator,
	from_date,
	to_date,
}: OperatorRequestQuery) => {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(employeeVerifyPerMinSlice.actions.loading());
		try {
			let chartData: AnalyticsChartData[] = [];
			const resp = await LogsApi.getVerifiedPerMin({
				operator,
				from_date,
				to_date,
			});
			chartData = [
				...chartData,
				...map(resp, (i) => ({
					date: moment(i.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
					value: i.value,
				})),
			];
			dispatch(employeeVerifyPerMinSlice.actions.update(chartData));
		} catch (e) {
			dispatch(employeeVerifyPerMinSlice.actions.error(e.message));
		}
	};
};
