import {
	createSlice,
	PayloadAction,
	ThunkDispatch,
	AnyAction,
} from "@reduxjs/toolkit";
import { FeedbackApi } from "api";
import { Feedback } from "schema/model";
import { FeedbackRewardState } from "schema/redux";
import { RootState } from "store";

const initState: FeedbackRewardState = {
	loading: false,
	error: null,
	success: false,
	feedback: null,
	popupVisible: false,
};

const feedbackRewardSlice = createSlice({
	name: "feedback-reward",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		showFeedbackRewardPopup: (state, action: PayloadAction<Feedback>) => {
			state.loading = false;
			state.feedback = action.payload;
			state.popupVisible = true;
		},
		success: (state) => {
			state.success = true;
		},
		clearFeedbackReward: () => initState,
	},
});

export const { clearFeedbackReward, showFeedbackRewardPopup } =
	feedbackRewardSlice.actions;
export const feedbackRewardReducer = feedbackRewardSlice.reducer;

export function rewardFeedback(coins: number, admin_message: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(feedbackRewardSlice.actions.loading());
		try {
			const feedback = getStore().feedbackReward.feedback;
			const response = await FeedbackApi.updateReward({
				id: feedback?.id,
				user_id: feedback?.user_id,
				admin_message,
				reward: { coins },
			});
			dispatch(feedbackRewardSlice.actions.success());
		} catch (err) {
			dispatch(feedbackRewardSlice.actions.error(err.message));
		}
	};
}
