import { createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "api";
import { KEY_ACCESS_TOKEN } from "config/constants";
import Cookies from "js-cookie";
import { AuthState } from "schema/redux";

const initialState: AuthState = {
	user: null,
	loading: false,
	signedIn: false,
	error: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		success: (state, action) => {
			state.loading = false;
			state.error = null;
			state.signedIn = true;
			state.user = action.payload;
		},
		logout: (state) => {
			Cookies.remove(KEY_ACCESS_TOKEN);
			state.signedIn = false;
			state.user = null;
		},
	},
});

export const { logout } = authSlice.actions;

/**
 *
 * @param {string} email
 * @param {string} password
 * @returns {Promise<void>}
 */

export const login = (email: string, password: string) => async (dispatch) => {
	dispatch(authSlice.actions.loading());
	try {
		const token = Cookies.get(KEY_ACCESS_TOKEN);
		if (token == undefined || token == "") {
			const credential = await AuthApi.login(email, password);
			const newToken = Cookies.set(KEY_ACCESS_TOKEN, credential.token, {
				expires: 1,
			});
		}
		const user = await AuthApi.getCurrent();
		dispatch(authSlice.actions.success(user));
	} catch (err) {
		dispatch(authSlice.actions.error(err.message));
	}
};

/**
 * Check user base on access token
 * @returns {void}
 */

export const checkUser = () => async (dispatch) => {
	dispatch(authSlice.actions.loading());
	try {
		const token = Cookies.get(KEY_ACCESS_TOKEN);
		if (token == undefined || token == "") {
			dispatch(authSlice.actions.error(""));
			return;
		}
		const user = await AuthApi.getCurrent();
		dispatch(authSlice.actions.success(user));
	} catch (err) {
		Cookies.remove(KEY_ACCESS_TOKEN);
		dispatch(authSlice.actions.error(err.message));
	}
};

export default authSlice.reducer;
