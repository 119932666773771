import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { notification } from "antd";
import { BackgroundApi } from "api";
import { BackgroundModel } from "schema/model";
import { BackgroundState } from "schema/redux";
import { RootState } from "store";

const initState: BackgroundState = {
	loading: false,
	error: null,
	success: false,
	backgrounds: {
		items: [],
		total: 0,
	},
	query: {
		page: 1,
		limit: 10,
	},
	createSuccess: false,
};

const backgroundSlice = createSlice({
	name: "daily-spark",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
			state.success = false;
			state.createSuccess = false;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
			state.success = false;
			state.createSuccess = false;
		},
		success: (state) => {
			state.loading = false;
			state.error = null;
			state.success = true;
		},
		createSuccess: (state) => {
			state.loading = false;
			state.error = null;
			state.createSuccess = true;
		},
		updateBackground: (state, { payload }) => {
			state.background = payload;
		},
		updateBackgrounds: (state, { payload }) => {
			state.backgrounds = payload;
		},
		// updateCategories: (state, { payload }) => {
		// 	state.categories = payload;
		// },
		updateBackgroundQuery: (state, { payload }) => {
			state.query = { ...state.query, page: payload };
		},
		// updateLanguage: (state, { payload }) => {
		// 	state.language = payload;
		// },
		clearBackground: () => initState,
	},
});

export const { clearBackground, updateBackground, updateBackgroundQuery } = backgroundSlice.actions;
export const backgroundReducer = backgroundSlice.reducer;

export function getBackgrounds() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(backgroundSlice.actions.loading());
		const { query } = getStore().background;
		try {
			const res = await BackgroundApi.getAllBackgrounds(query);
			if (res) {
				dispatch(backgroundSlice.actions.updateBackgrounds(res));
			}
			dispatch(backgroundSlice.actions.success());
		} catch (e) {
			dispatch(backgroundSlice.actions.error(e.message));
		}
	};
}

export function createNewBackground(data: BackgroundModel) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(backgroundSlice.actions.loading());
		try {
			const res = await BackgroundApi.postBackground(data);
			if (res) {
				dispatch(backgroundSlice.actions.updateBackgrounds(res));
			}
			dispatch(backgroundSlice.actions.createSuccess());
		} catch (e) {
			dispatch(backgroundSlice.actions.error(e.message));
		}
	};
}

export function updateBackgroundToEdit(data: BackgroundModel) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(backgroundSlice.actions.loading());
		try {
			const res = await BackgroundApi.postBackground(data);
			if (res) {
				dispatch(backgroundSlice.actions.updateBackgrounds(res));
			}
			dispatch(backgroundSlice.actions.createSuccess());
		} catch (e) {
			// dispatch(dailySparkSlice.actions.error(e.message));
		}
	};
}

export function deleteBackgroundByKey(key: string) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(backgroundSlice.actions.loading());
		try {
			const res = await BackgroundApi.deleteBackground(key);
			if (res) {
				dispatch(backgroundSlice.actions.updateBackgrounds(res));
			}
			dispatch(backgroundSlice.actions.success());
			notification.success({ message: 'Delete background successfully' })
		} catch (e) {
			dispatch(backgroundSlice.actions.error(e.message));
		}
	};
}
