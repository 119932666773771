import request from "api/request";
import { HOST } from "config/api";
import qs from "qs";
import { ApiListResponse } from "schema/api";
import { DailySparkCategory, QuoteModel, QuoteRequest } from "schema/model";
import { _toBaseUrl } from "utils";
import { DailySparkRequestQuery } from "./../../schema/api";

const Endpoint = _toBaseUrl(HOST) + "/daily-sparks";

export interface ISpecialDay {
	id?: string;
	name?: string;
}

export interface ICommonRequestParams {
	page?: string;
	limit?: string;
}

export const getAllSpecialDays = () => {
	const url = Endpoint + "/special-days";
	return request<ISpecialDay[]>("GET", url);
};

export const postNewSpecialDay = (name: string, date: string) => {
	console.log("name, date", name, date);
	const url = Endpoint + "/special-day";
	return request<ISpecialDay[]>("POST", url, { name: name, date: date });
};

export const deleteSpecialDay = (id: string) => {
	const url = Endpoint + "/special-day?id=" + id;
	return request<ISpecialDay[]>("DELETE", url);
};

export const getAllQuotes = (
	query: DailySparkRequestQuery,
	language: string,
) => {
	const { limit, page } = query;
	const qr = qs.stringify(
		{ limit, page },
		{
			indices: false,
			skipNulls: true,
			arrayFormat: "index",
		},
	);
	return request<ApiListResponse<QuoteModel>>(
		"GET",
		Endpoint + "/daily-sparks?" + qr,
		null,
		language,
	);
};

export const postNewQuote = (data: QuoteRequest) => {
	return request<ApiListResponse<QuoteModel>>(
		"POST",
		Endpoint + `/daily-spark`,
		{
			quote: data,
		},
	);
};

export const updateQuote = (data: QuoteRequest) => {
	return request<ApiListResponse<QuoteModel>>(
		"PUT",
		Endpoint + `/daily-spark?id=` + data.id,
		{
			quote: data,
		},
	);
};

export const getCategoriesByName = (name: string) => {
	return request<DailySparkCategory>(
		"GET",
		Endpoint + "/categories?name=" + name,
	);
};
