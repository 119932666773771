import {
	createSlice,
	ThunkDispatch,
	AnyAction,
	PayloadAction,
} from "@reduxjs/toolkit";
import { FeedbackApi } from "api";
import { ApiListResponse, FeedbackRequestQuery } from "schema/api";
import { Feedback } from "schema/model";
import { FeedbackListState } from "schema/redux";
import { RootState } from "store";

const listInitState: FeedbackListState = {
	loading: false,
	error: null,
	total: 0,
	feedbacks: [],
	query: { limit: 10, page: 1, type: "unread" },
};

const feedbackListSlice = createSlice({
	name: "feedback-list",
	initialState: listInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateData: (state, action: PayloadAction<ApiListResponse<Feedback>>) => {
			state.loading = false;
			state.feedbacks = action.payload.items;
			state.total = action.payload.total;
		},
		updateQuery: (state, action) => {
			state.query = action.payload;
		},
		removeFeedback: (state, action) => {
			state.feedbacks = state.feedbacks.filter((f) => f.id != action.payload);
			state.loading = false;
		},
		clearFeedbackList: () => listInitState,
	},
});

export const { clearFeedbackList, removeFeedback } = feedbackListSlice.actions;
export const feedbackListReducer = feedbackListSlice.reducer;

export function fetchFeedbacks(query: FeedbackRequestQuery = {}) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(feedbackListSlice.actions.loading());
		let cloneQuery = { ...getStore().feedbackList.query, ...query };
		dispatch(feedbackListSlice.actions.updateQuery(cloneQuery));
		try {
			const feedbacksResponse = await FeedbackApi.getFeedbacks(cloneQuery);
			dispatch(feedbackListSlice.actions.updateData(feedbacksResponse));
		} catch (err) {
			dispatch(feedbackListSlice.actions.error(err.message));
		}
	};
}

export function dismissFeedback(feedback_id: string) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(feedbackListSlice.actions.loading());
		try {
			// fetch api
			const response = await FeedbackApi.updateFeedback({
				id: feedback_id,
				type: "read",
			});
			dispatch(feedbackListSlice.actions.removeFeedback(feedback_id));
		} catch (err) {
			dispatch(feedbackListSlice.actions.error(err.message));
		}
	};
}
