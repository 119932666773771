import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { notification } from "antd";
import { DailySparkApi } from "api";
import { DailySparkState } from "schema/redux";
import { RootState } from "store";
import { ISpecialDay } from "./../../../api/_api/dailySpark";
import { QuoteRequest } from "./../../../schema/model";

const initState: DailySparkState = {
	loading: false,
	error: null,
	success: false,
	quotes: {
		items: [],
		total: 0,
	},
	quote: null,
	categories: null,
	query: {
		page: 1,
		limit: 10,
	},
	language: "vi",
	createSuccess: false,
	specialDays: [],
};

const dailySparkSlice = createSlice({
	name: "daily-spark",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
			state.success = false;
			state.createSuccess = false;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
			state.success = false;
		},
		success: (state) => {
			state.loading = false;
			state.error = null;
			state.success = true;
		},
		createSuccess: (state) => {
			state.loading = false;
			state.error = null;
			state.createSuccess = true;
		},
		updateSpecialDays: (state, { payload }) => {
			state.loading = false;
			state.specialDays = payload as ISpecialDay[];
		},
		updateQuote: (state, { payload }) => {
			state.quote = payload;
		},
		updateQuotes: (state, { payload }) => {
			state.quotes = payload;
		},
		updateCategories: (state, { payload }) => {
			state.categories = payload;
		},
		updateQuery: (state, { payload }) => {
			state.query = { ...state.query, page: payload };
		},
		updateLanguage: (state, { payload }) => {
			state.language = payload;
		},
		clearDailySpark: () => initState,
	},
});

export const { clearDailySpark, updateQuery, updateLanguage, updateQuote } =
	dailySparkSlice.actions;
export const dailySparkReducer = dailySparkSlice.reducer;

export function createSpecialDay(name: string, date: string, func: any) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(dailySparkSlice.actions.loading());
		try {
			const res = await DailySparkApi.postNewSpecialDay(name, date);
			if (res) {
				func(res[res.length - 1].id)
				dispatch(
					dailySparkSlice.actions.updateSpecialDays(res),
				);
			}
			dispatch(dailySparkSlice.actions.success());
		} catch (e) {
			dispatch(dailySparkSlice.actions.error(e.message));
		}
	};
}

export function deleteSpecialDayById(id: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(dailySparkSlice.actions.loading());
		try {
			const res = await DailySparkApi.deleteSpecialDay(id);
			if (res) {
				dispatch(
					dailySparkSlice.actions.updateSpecialDays(res),
				);
			}
			dispatch(dailySparkSlice.actions.success());
		} catch (e) {
			dispatch(dailySparkSlice.actions.error(e.message));
		}
	};
}

export function getSpecialDays() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(dailySparkSlice.actions.loading());
		try {
			const res = await DailySparkApi.getAllSpecialDays();
			if (res) {
				dispatch(dailySparkSlice.actions.updateSpecialDays(res));
			}
			dispatch(dailySparkSlice.actions.success());
		} catch (e) {
			dispatch(dailySparkSlice.actions.error(e.message));
		}
	};
}

export function getQuotes() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(dailySparkSlice.actions.loading());
		const { query, language } = getStore().dailySpark;
		try {
			const res = await DailySparkApi.getAllQuotes(query, language);
			if (res) {
				dispatch(dailySparkSlice.actions.updateQuotes(res));
			}
			dispatch(dailySparkSlice.actions.success());
		} catch (e) {
			dispatch(dailySparkSlice.actions.error(e.message));
		}
	};
}

export function createNewQuote(data: QuoteRequest) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(dailySparkSlice.actions.loading());
		try {
			const res = await DailySparkApi.postNewQuote(data);
			if (res) {
				dispatch(dailySparkSlice.actions.updateQuotes(res));
			}
			dispatch(dailySparkSlice.actions.createSuccess());
		} catch (e) {
			dispatch(dailySparkSlice.actions.error(e.message));
		}
	};
}

export function getCategoriesByName(name: string) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(dailySparkSlice.actions.loading());
		try {
			const res = await DailySparkApi.getCategoriesByName(name);
			if (res) {
				dispatch(dailySparkSlice.actions.updateCategories(res));
			}
			dispatch(dailySparkSlice.actions.success());
		} catch (e) {
			dispatch(dailySparkSlice.actions.error(e.message));
		}
	};
}

export function editQuote(data: QuoteRequest) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(dailySparkSlice.actions.loading());
		try {
			const res = await DailySparkApi.updateQuote(data);
			if (res) {
				dispatch(dailySparkSlice.actions.updateQuotes(res));
			}
			dispatch(dailySparkSlice.actions.success());
			notification.success({ message: "Edit quote successfully" });
		} catch (e) {
			dispatch(dailySparkSlice.actions.error(e.message));
		}
	};
}
