import {
	createSlice,
	ThunkDispatch,
	AnyAction,
	PayloadAction,
} from "@reduxjs/toolkit";
import { message } from "antd";
import { ProductApi } from "api";
import { cloneDeep, filter, findIndex, forEach, map, reduce } from "lodash";
import { Product } from "schema/model";
import { ProductSingleState } from "schema/redux";
import { RootState } from "store";

const productSingleInitState: ProductSingleState = {
	loading: false,
	product: null,
	error: null,
	items: [],
	success: false,
};

const productSingleSlice = createSlice({
	name: "product-single",
	initialState: productSingleInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateProduct: (state, action) => {
			state.product = { ...state.product, ...action.payload };
			state.loading = false;
		},
		updateCoreProducts: (state, action) => {
			state.items = action.payload;
			state.loading = false;
		},
		updateItemQuantity: (
			state,
			action: PayloadAction<{ product_code?: string; quantity?: number }>,
		) => {
			const { product_code, quantity } = action.payload;
			const _idx = findIndex(
				state.items,
				(i: Product) => i.product_code === product_code,
			);
			if (_idx > -1) state.items[_idx]["quantity"] = quantity;
		},
		success: (state) => {
			state.success = true;
		},
		clearProductSingle: () => productSingleInitState,
	},
});

export const { clearProductSingle, updateItemQuantity, updateProduct } =
	productSingleSlice.actions;

export const productSingleReducer = productSingleSlice.reducer;

export function fetchCoreProducts() {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(productSingleSlice.actions.loading());
		try {
			const data = await ProductApi.get("core");
			dispatch(productSingleSlice.actions.updateCoreProducts(data));
		} catch (err) {
			dispatch(productSingleSlice.actions.error(err.message));
		}
	};
}

export function createProduct() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(productSingleSlice.actions.loading());
		try {
			type itemType = {
				product_code?: string;
				quantity?: number;
			};
			const { product, items } = cloneDeep(getStore().productSingle);
			const valid_arr: any = filter(items, ({ quantity }: Product) => quantity);
			if (!valid_arr.length) {
				const msg = "Vui lòng chọn ít nhất 1 sản phẩm";
				message.warning(msg);
				dispatch(productSingleSlice.actions.error(msg));
				return;
			}
			const item_arr: itemType[] = map(
				valid_arr,
				({ product_code, quantity }: Product) => ({ product_code, quantity }),
			);
			const product_code: string[] = reduce(
				item_arr,
				(result, curr) => {
					result.push(...[curr.quantity, curr.product_code]);
					return result;
				},
				[],
			);

			let price: number = reduce(
				valid_arr,
				(sum: number, curr: Product) => {
					sum += curr.quantity * curr.price;
					return sum;
				},
				0,
			);

			// product.start_date = moment(product.start_date).startOf("day").utc();
			// product.expiration_date = product.expiration_date
			// 	? moment(product.expiration_date).endOf("day").utc()
			// 	: undefined;
			forEach(product, (v, k) => {
				const key = k.split("-");
				if (key.length > 1) {
					product[key[0]] = {
						...product[key[0]],
						[key[1]]: v,
					};
					delete product[k];
				}
			});

			const newProduct: Product = {
				...product,
				product_type: "combo",
				items: item_arr,
				product_code: product_code.join("_"),
				price,
				currency: valid_arr[0].currency,
			};
			const { message: msg } = await ProductApi.createCombo(newProduct);
			const resp = await ProductApi.uploadComboImage(
				product_code.join("_"),
				product.file_obj.file,
			);
			console.log(resp);
			if (msg) message.success(msg);
			dispatch(productSingleSlice.actions.success());
		} catch (err) {
			message.error(err.message);
			dispatch(productSingleSlice.actions.error(err.message));
		}
	};
}
