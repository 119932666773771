/* eslint-disable react-hooks/exhaustive-deps */
import { PoweroffOutlined } from "@ant-design/icons";
import { Divider, Layout, Menu, Modal } from "antd";
import { APP_LOGO } from "assets";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import routes from "routers/routes";
import { UserRole } from "schema/general";
import { RootState } from "store";
import { useWindowSize } from "uikit";
import { WindowSize } from "uikit/types";
import { expand, toggle, updateTitle } from "./layoutWrapperSlice";
import "./SideBar.css";

const _renderMenu = (role: UserRole): JSX.Element[] => {
	return map(
		routes.filter(
			({ menu, roles }) => menu != null && roles?.indexOf(role) >= 0,
		),
		({ menu, path, ...rest }, idx) => (
			<React.Fragment key={path.toString()}>
				{menu.header === true ? <Divider /> : null}
				<Menu.Item key={path.toString()} icon={menu.icon.render()}>
					{menu.title}
				</Menu.Item>
			</React.Fragment>
		),
	);
};

export default function SideBar() {
	const location = useLocation().pathname;
	const history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state: RootState) => state.auth?.user);

	const [selectedMenu, setSelectedMenu] = useState("/dashboard");

	const sidebarCollapsed = useSelector(
		(state: RootState) => state.layoutWrapper.collapsed,
	);

	const windowSize: WindowSize = useWindowSize();

	useEffect(() => {
		setSelectedMenu(location);
		const selectedRoute = routes.find(
			(element) => element.path.toString() === location,
		);
		if (selectedRoute != null) {
			dispatch(updateTitle(selectedRoute.title));
		}
	}, [location]);

	useEffect(() => {
		if (windowSize.width > 768) {
			dispatch(expand());
		}
	}, [windowSize.width]);

	return (
		<Layout.Sider
			className={
				"site-sider-wrapper" + (windowSize.width > 768 ? " desktop" : " mobile")
			}
			trigger={null}
			collapsible
			collapsed={sidebarCollapsed}
		>
			<div className="site-logo">
				<img
					className={
						sidebarCollapsed ? "site-logo-img-collapsed" : "site-logo-img"
					}
					alt="logo"
					src={APP_LOGO}
				/>
			</div>
			<Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys={[
					location === "" || location === undefined ? "/" : location,
				]}
				selectedKeys={[selectedMenu]}
				onSelect={({ selectedKeys }) => {
					if (selectedKeys.length === 0) return;
					if (selectedKeys.indexOf("/logout") !== -1) {
						Modal.confirm({
							title: "Đăng xuất",
							icon: <PoweroffOutlined />,
							content: "Bạn muốn đăng xuất hệ thống?",
							onOk: () => history.push("/logout"),
						});
						return;
					}
					history.push(selectedKeys[0].toString());
					setSelectedMenu(selectedKeys[0].toString());
					dispatch(expand());
					if (isMobile)
						dispatch(toggle());
				}}
			>
				{_renderMenu(user.role)}
				<div style={{ flexGrow: 1 }}></div>
				<Divider />
				<Menu.Item key="/logout" icon={<PoweroffOutlined />}>
					Logout
				</Menu.Item>
			</Menu>
		</Layout.Sider>
	);
}
