import { AnyAction, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { NotificationApi } from 'api';
import { ApiListResponse, NotificationRequestQuery } from 'schema/api';
import { NotificationHistorySchedule } from 'schema/model';
import { NotificationHistoryState } from 'schema/redux';
import { RootState } from 'store';

const initState: NotificationHistoryState = {
	loading: false,
	error: null,
	total: 0,
	schedules: [],
	query: { page_number: 1, page_chunk_size: 10 },
};

const notificationHistorySlice = createSlice({
	name: 'notification-history',
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		},
		updateData: (state, action: PayloadAction<ApiListResponse<NotificationHistorySchedule>>) => {
			state.loading = false;
			state.schedules = action.payload.items;
			state.total = action.payload.total;
		},
		updateQuery: (state, { payload }) => {
			state.query = payload;
		},
		clearNotificationHistory: () => initState,
	},
});

export const { clearNotificationHistory } = notificationHistorySlice.actions;
export const notificationHistoryReducer = notificationHistorySlice.reducer;

export function fetchNotificationHistory(query: NotificationRequestQuery) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(notificationHistorySlice.actions.loading());
		dispatch(notificationHistorySlice.actions.updateQuery(query));
		try {
			const response = await NotificationApi.getSchedules(query);
			dispatch(notificationHistorySlice.actions.updateData(response));
		} catch (err) {
			dispatch(notificationHistorySlice.actions.error(err.message));
		}
	};
}