import { createSlice, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { ProductApi } from "api";
import { ProductType } from "schema/general";
import { ProductListState } from "schema/redux";

const productListInitState: ProductListState = {
	loading: false,
	error: null,
	products: [],
};

const productListSlice = createSlice({
	name: "product-list",
	initialState: productListInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		update: (state, action) => {
			state.products = action.payload;
			state.loading = false;
		},
		clearProductList: () => productListInitState,
	},
});

export const { clearProductList } = productListSlice.actions;
export const productListReducer = productListSlice.reducer;

export function fetchProducts(product_type: ProductType) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(productListSlice.actions.loading());
		try {
			const data = await ProductApi.get(product_type);
			dispatch(productListSlice.actions.update(data));
		} catch (err) {
			dispatch(productListSlice.actions.error(err.message));
		}
	};
}
