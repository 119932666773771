import {
	createSlice,
	PayloadAction,
	ThunkDispatch,
	AnyAction,
} from "@reduxjs/toolkit";
import { ReportApi } from "api";
import { ReportAction } from "schema/general";
import { ReportGroup } from "schema/model";
import { ReportRewardState } from "schema/redux";
import { RootState } from "store";

const initState: ReportRewardState = {
	loading: false,
	error: null,
	success: false,
	report: null,
	popupVisible: false,
	action: null,
};

const reportRewardSlice = createSlice({
	name: "report-reward",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		showReportRewardPopup: (
			state,
			action: PayloadAction<{ report: ReportGroup; action: ReportAction }>,
		) => {
			state.loading = false;
			state.report = action.payload.report;
			state.action = action.payload.action;
			state.popupVisible = true;
		},
		success: (state) => {
			state.success = true;
		},
		clearReportReward: () => initState,
	},
});

export const { clearReportReward, showReportRewardPopup } =
	reportRewardSlice.actions;
export const reportRewardReducer = reportRewardSlice.reducer;

export function rewardReport(coins: number, admin_message: string) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(reportRewardSlice.actions.loading());
		try {
			const report = getStore().reportReward.report;
			const userStatus =
				getStore().reportReward.action === "ban" ? "banned_break" : "banned_underage" ? "banned_underage" : "pending";

			const response = await ReportApi.updateReward({
				report_ids: report?.reports?.map((r) => r.report_id) ?? [],
				user_ids: report?.reports?.map((r) => r.reported_by_id) ?? [],
				admin_message,
				reward: { coins },
				reported_id: report?.reported_id,
				status: "pending",
			});
			dispatch(reportRewardSlice.actions.success());
		} catch (err) {
			dispatch(reportRewardSlice.actions.error(err.message));
		}
	};
}
