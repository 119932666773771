import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { RcFile } from "antd/lib/upload";
import { TarotApi } from "api";
import { TartorModel } from "schema/model";
import { TarotState } from "schema/redux";
import { RootState } from "store";

const initState: TarotState = {
	loading: false,
	error: null,
	success: false,
	tarots: {
		items: [],
		total: 0,
	},
	query: {
		page: 1,
		limit: 10,
	},
	createSuccess: false,
};

const tarotSlice = createSlice({
	name: "tarot-card",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
			state.success = false;
			state.createSuccess = false;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
			state.success = false;
			state.createSuccess = false;
		},
		success: (state) => {
			state.loading = false;
			state.error = null;
			state.success = true;
		},
		createSuccess: (state) => {
			state.loading = false;
			state.error = null;
			state.createSuccess = true;
		},
		updateTarot: (state, { payload }) => {
			state.tarot = payload;
		},
		updateTarots: (state, { payload }) => {
			state.tarots = payload;
		},
		updateTarotQuery: (state, { payload }) => {
			state.query = { ...state.query, page: payload };
			state.createSuccess = false
		},
		// updateLanguage: (state, { payload }) => {
		// 	state.language = payload;
		// },
		clearTarot: () => initState,
	},
});

export const tarotReducer = tarotSlice.reducer;

export function getTarots() {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(tarotSlice.actions.loading());
		const { query } = getStore().background;
		try {
			const res = await TarotApi.getAllTarots(query);
			if (res) {
				dispatch(tarotSlice.actions.updateTarots(res));
			}
			dispatch(tarotSlice.actions.success());
		} catch (e) {
			dispatch(tarotSlice.actions.error(e.message));
		}
	};
}

export function createNewTarot(data: TartorModel) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(tarotSlice.actions.loading());
		try {
			const { image, ...clone } = data;
			const res = await TarotApi.postTarotImage(image as RcFile)
			if (res) {
				const tarotData: TartorModel = {image: res[0], ...clone}
				const result = await TarotApi.postTarot(tarotData);
				dispatch(tarotSlice.actions.updateTarots(result));
			}
			dispatch(tarotSlice.actions.createSuccess());
		} catch (e) {
			dispatch(tarotSlice.actions.error(e.message));
		}
	};
}
