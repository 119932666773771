import { RcFile } from "antd/lib/upload";
import request from "api/request";
import { HOST } from "config/api";
import qs from "qs";
import { ApiListResponse, DailySparkRequestQuery } from "schema/api";
import { TarotResponseModel, TartorModel } from "schema/model";
import { _toBaseUrl } from "utils";

const Endpoint = _toBaseUrl(HOST) + "/daily-sparks";

export const getAllTarots = (query: DailySparkRequestQuery) => {
	const { limit, page } = query;
	const qr = qs.stringify(
		{ limit, page },
		{
			indices: false,
			skipNulls: true,
			arrayFormat: "index",
		},
	);
	return request<ApiListResponse<TarotResponseModel>>(
		"GET",
		Endpoint + "/tarots?" + qr,
	);
};

export const postTarot = (data: TartorModel) => {
	const clone = { ...data };

	return request<ApiListResponse<TarotResponseModel>>(
		"POST",
		Endpoint + `/tarot`, //?font=${clone.font}&color=${clone.color}
		{tarot: clone},
	);
};

export const postTarotImage = (image: RcFile) => {
	const formData = new FormData();
	formData.append("file", image);
	return request<any>("POST", Endpoint + "/tarot-image", formData);
};
