import {
	createSlice,
	ThunkDispatch,
	AnyAction,
	PayloadAction,
} from "@reduxjs/toolkit";
import { DiscountApi, ProductApi } from "api";
import { filter, find, forEach, map } from "lodash";
import { Discount, Product } from "schema/model";
import { DiscountCreateState } from "schema/redux";
import { RootState } from "store";

const discountCreateInitState: DiscountCreateState = {
	loading: false,
	error: null,
	success: false,
	products: [],
};

const discountCreateSlice = createSlice({
	name: "discount-create",
	initialState: discountCreateInitState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		success: (state) => {
			state.success = true;
			state.loading = false;
		},
		updateProducts: (state, action: PayloadAction<Product[]>) => {
			state.products = action.payload;
			state.loading = false;
		},
		clearDiscountCreate: () => discountCreateInitState,
	},
});

export const { clearDiscountCreate } = discountCreateSlice.actions;
export const discountCreateReducer = discountCreateSlice.reducer;

export function fetchCombosForDiscount() {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(discountCreateSlice.actions.loading());
		try {
			const data = await ProductApi.get("combo");
			dispatch(discountCreateSlice.actions.updateProducts(data));
		} catch (err) {
			dispatch(discountCreateSlice.actions.error(err.message));
		}
	};
}

/**
 *
 * @param {any} data Form data
 * @param {boolean[]} checkArr Checked option array whether that product use price or percentage discount
 * @returns {Promise<void>}
 */

export function createDiscount(data: any, checkArr: boolean[]) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(discountCreateSlice.actions.loading());
		const comboProducts = getStore().discountCreate.products;

		// filter null or undefined values
		forEach(data, (v, k) => {
			if (v === undefined || v === null) delete data[k];
		});

		const { image, product_code, ...discount } = data;

		// Initiate discount object
		const newDiscount: Discount = {
			...discount,
		};
		// get full product
		let products: any = map(product_code, (p) => {
			const product = find(
				comboProducts,
				(_p: Product) => _p.product_code === p.product_code,
			);
			return { ...product, ...p };
		});

		// get only property
		products = map(products, (p: Product, idx: number) => {
			let normal_percent_off: number;
			let lite_percent_off: number;
			let pro_percent_off: number;
			let discount_price: number;
			let lite_price: number;
			let pro_price: number;

			if (checkArr[idx]) {
				normal_percent_off =
					(1 -
						(p.discount_price ? parseFloat(p.discount_price) / p.price : 0)) *
					100;
				lite_percent_off =
					(1 - (p.lite_price ? parseFloat(p.lite_price) / p.price : 0)) * 100;
				pro_percent_off =
					(1 - (p.pro_price ? parseFloat(p.pro_price) / p.price : 0)) * 100;
			} else {
				discount_price =
					p.price *
					(1 -
						(p.normal_percent_off
							? parseFloat(p.normal_percent_off) / 100
							: 0));
				lite_price =
					p.price *
					(1 - (p.lite_percent_off ? parseFloat(p.lite_percent_off) / 100 : 0));
				pro_price =
					p.price *
					(1 - (p.pro_percent_off ? parseFloat(p.pro_percent_off) / 100 : 0));
			}

			// const normal_percent_off: number =
			// 	(1 - (p.discount_price ? parseFloat(p.discount_price) / p.price : 0)) *
			// 	100;
			// const lite_percent_off: number =
			// 	(1 - (p.lite_price ? parseFloat(p.lite_price) / p.price : 0)) * 100;
			// const pro_percent_off: number =
			// 	(1 - (p.pro_price ? parseFloat(p.pro_price) / p.price : 0)) * 100;

			// Round up all discount
			return {
				product_code: p.product_code,
				image: typeof p.image === "string" ? p.image : "",
				normal_percent_off:
					normal_percent_off && Math.round(normal_percent_off),
				lite_percent_off: lite_percent_off && Math.round(lite_percent_off),
				pro_percent_off: pro_percent_off && Math.round(pro_percent_off),
				pro_price: pro_price && Math.round(pro_price),
				discount_price: discount_price && Math.round(discount_price),
				lite_price: lite_price && Math.round(lite_price),
			};
		});

		// remove undefined field

		products = map(products, (p) => {
			forEach(p, (v, k) => {
				if (v === undefined || v === null) delete p[k];
			});
			return p;
		});

		newDiscount.start_date = newDiscount.start_date.startOf("day").utc();
		if (newDiscount.expiration_date)
			newDiscount.expiration_date = newDiscount.expiration_date
				.endOf("day")
				.utc();
		newDiscount.products = products;

		try {
			const { discount_id } = await DiscountApi.createDiscount(newDiscount);
			const valid = filter(product_code, (p) => p.image?.file);
			const promises = map(valid, async (p) => {
				return await DiscountApi.uploadImage(
					discount_id,
					p.product_code,
					p.image?.file,
				);
			});
			Promise.all(promises)
				.then((_) => {
					dispatch(discountCreateSlice.actions.success());
				})
				.catch((e) => {
					dispatch(discountCreateSlice.actions.error(e.message));
				});
		} catch (e) {
			dispatch(discountCreateSlice.actions.error(e.message));
		}
	};
}
