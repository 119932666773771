import { AnyAction, createSlice, ThunkDispatch } from "@reduxjs/toolkit";
import { notification } from "antd";
import { PromptApi } from "api";
import { ReportPostQuery } from "schema/api";
import { PromptItem } from "schema/model";
import { PromptState } from "schema/redux";
import { RootState } from "store";

const initialState: PromptState = {
	loading: false,
	prompts: [],
	query: {
		page_chunk_size: 10,
		page_number: 1,
	},
	total: 0,
	success: false,
	prompt: null,
};

const promptSlice = createSlice({
	initialState: initialState,
	name: "leka-flag",
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.success = false;
		},
		updateListPrompt: (state, { payload }) => {
			state.prompts = payload.items as PromptItem[];
			state.total = payload.total;
			state.loading = false;
		},
		updateQuery: (state, { payload }) => {
			state.query = payload;
		},
		createSucceed: (state) => {
			state.success = true;
			state.loading = false;
		},
		updatePrompt: (state, { payload }) => {
			state.loading = false;
			state.prompt = payload as PromptItem;
		},
	},
});

export const promptReducer = promptSlice.reducer;
export const { loading, updateQuery } = promptSlice.actions;

export function getListPrompt(query: ReportPostQuery) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		const { query } = getStore().prompt;
		try {
			const res = await PromptApi.getPrompts(query);
			if (res) {
				dispatch(promptSlice.actions.updateListPrompt({ ...res }));
			}
		} catch (err) {}
	};
}

export function createNewPrompt(query: PromptItem) {
	return async (
		dispatch: ThunkDispatch<{}, {}, AnyAction>,
		getStore: () => RootState,
	) => {
		dispatch(promptSlice.actions.loading());
		try {
			const res = await PromptApi.postPrompt(query);
			if (res) {
				notification.success({ message: "Create new prompt successfully" });
			}
		} catch (err) {}
	};
}

export function getPromptById(id: string) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		try {
			const res = await PromptApi.getPromptById(id);
			if (res) {
				dispatch(promptSlice.actions.updatePrompt(res));
			}
		} catch (err) {}
	};
}

export function editPromptById(id: string, data: PromptItem) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		try {
			const res = await PromptApi.putPrompt(id, data);
			if (res) {
				notification.success({ message: "Edit prompt successfully" });
				
			}
		} catch (err) {}
	};
}
