import { createSlice, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { NotificationApi } from "api";
import { forEach, isEmpty } from "lodash";
import { BaseCreateState } from "schema/redux";

const initState: BaseCreateState = {
	loading: false,
	error: null,
	success: false,
};

const notificationSlice = createSlice({
	name: "notification",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		success: (state) => {
			state.loading = false;
			state.success = true;
		},
		clearNotification: () => initState,
	},
});

export const { clearNotification } = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;

export function sendNotification(formData: any) {

	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(notificationSlice.actions.loading());
		try {
			if (formData.age_range && formData.age_range.length) {
				const [start, end] = formData.age_range;
				formData.age_range = `${start},${end}`;
				// console.log('formData.age_range', formData.age_range);
			}
			// if (formData.include_ids) {
			// 	const ids = formData.include_ids.split(",");
			// 	formData.include_ids = ids;
			// }
			let body = {};

			forEach(formData, (v, k) => {
				let key = k.split("-");
				if (key.length > 1) {
					body[key[0]] = { ...body[key[0]], [key[1]]: v };
				}
			});

			body['start_date'] = formData.start_date;
			body['end_date'] = formData.end_date;

			forEach(body, (v, k) => {
				if (isEmpty(v)) delete body[k];
			});

			if (formData.interval) {
				body[formData.interval] = formData.repeatEvery;
			}

			forEach(formData, (v, k) => {
				if (isEmpty(v)) delete formData[k];
			});
			formData.body = body;

			if (formData.all_user) {
				await NotificationApi.send({ body: formData.body });
			} else await NotificationApi.send(formData);
			dispatch(notificationSlice.actions.success());
		} catch (e) {
			dispatch(notificationSlice.actions.error(e.message));
		}
	};
}
