import { HOST } from "config/api";
import {
	ApiListResponse,
	FeedbackRequestQuery,
	GeneralApiResponse,
} from "schema/api";
import { Feedback, FeedbackRewardRequest } from "schema/model";
import request from "../request";

const FeedbackEndpoint = HOST + "/feedbacks";
const FeedbackRewardEndpoint = HOST + "/feedback/reward";

export const getFeedbacks = (query: FeedbackRequestQuery) =>
	request<ApiListResponse<Feedback>>("GET", FeedbackEndpoint, query);

export const updateFeedback = (feedback: Feedback) =>
	request<GeneralApiResponse>(
		"PUT",
		FeedbackEndpoint + "/" + feedback.id,
		feedback,
	);

export const updateReward = (reward: FeedbackRewardRequest) =>
	request<GeneralApiResponse>("PUT", FeedbackRewardEndpoint, reward);
