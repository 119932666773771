import { RcFile } from "antd/lib/upload";
import { HOST } from "config/api";
import { GeneralApiResponse } from "schema/api";
import { Product } from "schema/model";
import { _toBaseUrl } from "utils";
import request from "../request";

const ProductEndpoint = _toBaseUrl(HOST) + "/product";

export const get = (product_type: string) =>
	request<Product[]>("GET", ProductEndpoint + "s", { product_type });

export const createCombo = (product: Product) =>
	request<GeneralApiResponse>("POST", ProductEndpoint + "/combo", { product });

export const uploadComboImage = (product_code: string, file: RcFile) => {
	const formData = new FormData();
	formData.append("file_obj", file);
	return request(
		"POST",
		_toBaseUrl(HOST) + `/combo-image?product_code=${product_code}`,
		formData,
	);
};
