import { LEKA_HOST } from "config/api";
import qs from "qs";
import { ReportPostQuery } from "schema/api";
import { ReportedPost } from "schema/model";
import request from "../request";

const UserEndPoint = LEKA_HOST + "/admin";

export const getReportPosts = (query: ReportPostQuery) => {
	const q = qs.stringify(
		{ ...query },
		{
			indices: false,
			skipNulls: true,
			arrayFormat: "index",
		},
	);
	return request<{posts: ReportedPost[], total_posts: number}>(
		"GET",
		UserEndPoint + "/reported-posts?" + q,
	);
};

export const banUser = (userId: string) => {
	return request<any>("POST", UserEndPoint + "/ban/" + userId)
}

export const resetFlag = (postId: string) => {
	return request<any>("POST", UserEndPoint + "/reset-flag/" + postId)
}

export const deletePost = (postId: string) => {
	return request<any>("DELETE", UserEndPoint + "/remove/" + postId)
}
