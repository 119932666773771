import { flatten, forEach, times } from "lodash";
import moment from "moment";
import {
	AnalyticsChartData,
	AnalyticsTableData,
	TimeUnit,
} from "schema/general";
import { AnalyticsItem, VerificationTrackingItem } from "schema/model";

export async function processAnalyticsData({
	metrics,
	initChartData = [],
	initTableData = [],
	fetchData,
	shouldDisplayNormalChart,
}: {
	metrics: string[];
	initChartData?: AnalyticsChartData[];
	initTableData?: AnalyticsTableData[];
	fetchData: (metric: string) => Promise<AnalyticsItem[]>;
	shouldDisplayNormalChart?: boolean;
}) {
	let chartData: AnalyticsChartData[] = [...initChartData];
	let tableData: AnalyticsTableData[] = [...initTableData];
	let data: any = [];

	let rawData: {
		[key: string]: { [key: string]: number | string };
	} = {};
	let allDates: string[] = [];
	for (const metric of metrics) {
		console.log(`[Get] Metric ${metric}...`);
		let items: AnalyticsItem[] = await fetchData(metric);
		let itemData: { [key: string]: number | string } = {};
		for (const i of items) {
			itemData = {
				...itemData,
				[i.date]: `${i.value} - ${i.performances}`,
			};
		}
		rawData = { ...rawData, [metric]: itemData };
		if (shouldDisplayNormalChart) {
			chartData = [
				...chartData,
				...items.map<AnalyticsChartData>((i) => {
					return {
						date: moment(i.date).format("DD/MM/YYYY"),
						value: i.value,
						category: metric,
					};
				}),
			];
		} else {
			if (items.length % 2 !== 0) items.pop();
			data = [
				...data,
				...times(items.length / 2, (i) => {
					return [
						{
							date: moment(items[i + items.length / 2].date).format(
								"DD/MM/YYYY",
							),
							value: items[i + items.length / 2].value,
							category: metric,
							name: `${moment(items[i].date).format("DD/MM/YYYY")} - ${moment(
								items[i + items.length / 2].date,
							).format("DD/MM/YYYY")}`,
						},
						{
							date: moment(items[i + items.length / 2].date).format(
								"DD/MM/YYYY",
							),
							value: items[i].value,
							category: "@" + metric,
							name: `${moment(items[i].date).format("DD/MM/YYYY")} - ${moment(
								items[i + items.length / 2].date,
							).format("DD/MM/YYYY")}`,
						},
					];
				}),
			];
			chartData = flatten(data);
		}
		allDates = [...allDates, ...items.map<string>((i) => i.date)];
		console.log(`[Get] Metric ${metric}... Done`);
	}

	allDates = allDates.filter((v, i, a) => a.indexOf(v) === i); // get unique
	for (const date of allDates) {
		const dateString = moment(date).format("DD/MM/YYYY");
		const existed = tableData.findIndex((t) => t["date"] == dateString);
		let item: AnalyticsTableData =
			existed == -1
				? {
						date: dateString,
				  }
				: tableData[existed];
		for (const metric of metrics) {
			item = { ...item, [metric]: rawData[metric][date] };
		}
		if (existed == -1) {
			tableData.push(item);
		} else {
			tableData[existed] = item;
		}
	}
	return { chartData, tableData };
}

export async function processVerificationTime({
	fetchData,
	unit = "s",
}: {
	unit: TimeUnit;
	fetchData?: () => Promise<VerificationTrackingItem[]>;
}): Promise<AnalyticsChartData[]> {
	const data = await fetchData();
	console.log(data);
	let chartData: AnalyticsChartData[] = [];
	forEach(data, (i) => {
		switch (unit) {
			case "s":
				chartData.push({
					date: moment(i.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
					value: i.first_verified,
					category: "first_verified",
				});
				chartData.push({
					date: moment(i.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
					value: i.time_to_actions,
					category: "time_to_actions",
				});
				break;
			case "m":
				chartData.push({
					date: moment(i.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
					value: i.first_verified / 60,
					category: "first_verified",
				});
				chartData.push({
					date: moment(i.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
					value: i.time_to_actions / 60,
					category: "time_to_actions",
				});
				break;
			case "h":
				chartData.push({
					date: moment(i.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
					value: i.first_verified / (60 * 60),
					category: "first_verified",
				});
				chartData.push({
					date: moment(i.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
					value: i.time_to_actions / (60 * 60),
					category: "time_to_actions",
				});
				break;
			default:
				break;
		}
	});
	return chartData;
}
