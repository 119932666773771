import { createSlice, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { AchievementApi } from "api";
import { forEach } from "lodash";
import { Achievement } from "schema/model";
import { BaseCreateState } from "schema/redux";

const initState: BaseCreateState = {
	loading: false,
	error: null,
	success: false,
};

const achievementSlice = createSlice({
	name: "achievement-new",
	initialState: initState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
		},
		error: (state, action) => {
			state.error = action.payload;
			state.loading = false;
			state.success = false;
		},
		success: (state) => {
			state.loading = false;
			state.error = null;
			state.success = true;
		},
		clearAchievement: () => initState,
	},
});

export const { clearAchievement } = achievementSlice.actions;
export const achievementReducer = achievementSlice.reducer;

/**
 * createAchievement
 * This action will create achievment
 * It takes form data and transform to Taks interface
 * @param {any} data Basically the form data
 * @returns {void}
 */

export function createAchievement(data: any) {
	return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
		dispatch(achievementSlice.actions.loading());
		try {
			const body: Achievement = {};

			// remove undefined or null
			forEach(data, (v, k) => {
				if (v === null || v === undefined) delete data[k];
			});

			// transform data
			forEach(data, (v, k) => {
				let key = k.split("-");
				if (key.length > 1) {
					if (key[0] === "item") {
						body["reward_value"] = { ...body["reward_value"], [key[1]]: v };
					} else {
						body[key[1]] = { ...body[key[1]], [key[0]]: v };
					}
				} else if (k !== "file_obj") {
					body[k] = v;
				}
			});

			const { achievement_id } = await AchievementApi.createAchievement(body);
			if (achievement_id) {
				const file = data?.file_obj?.file;
				if (file)
					await AchievementApi.uploadAchievementImage(achievement_id, file);
			}
			dispatch(achievementSlice.actions.success());
		} catch (e) {
			dispatch(achievementSlice.actions.error(e.message));
		}
	};
}
