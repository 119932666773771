import { HOST } from "config/api";
import {
	AnalyticsRequestQuery,
	ApiListResponse,
	LogRequestQuery,
	OperatorRequestQuery,
} from "schema/api";
import { AnalyticsItem, LogRawItem, LogWarningItem } from "schema/model";
import request from "../request";

const LogsEndPoint = HOST + "/logs";
const NotesEndPoint = HOST + "/notes";

export const getLogs = (query: LogRequestQuery) =>
	request<ApiListResponse<LogRawItem>>("GET", LogsEndPoint + "/raw", query);

export const getSummaryLogs = (query: AnalyticsRequestQuery) =>
	request<AnalyticsItem[]>("GET", LogsEndPoint + "/summary", query);

export const getNotes = (query: LogRequestQuery) =>
	request<LogWarningItem[]>("GET", NotesEndPoint, query);

export const sendNote = (note: LogWarningItem) =>
	request<LogWarningItem>("POST", NotesEndPoint, { note });

export const getVerificationTime = (q: OperatorRequestQuery) =>
	request<any>("GET", HOST + "/verification_times", q);

export const getVerifiedPerMin = (q: OperatorRequestQuery) =>
	request<any>("GET", HOST + "/verified_per_min", q);
