import { ProductType } from "schema/general";

export const KEY_ACCESS_TOKEN = "access-token";

export const PRODUCT_TYPES: ProductType[] = [
	"coin",
	"combo",
	"core",
	"special",
	"subcription",
];
