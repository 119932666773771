import request from "api/request";
import { HOST } from "config/api";
import { forEach } from "lodash";
import { BannerApiRequest } from "schema/api";
import { Banner } from "schema/model";
import { _toBaseUrl } from "utils";

const BannerEndpoint = _toBaseUrl(HOST) + "/banners";

export const getAllBanners = () => request<Banner[]>("GET", BannerEndpoint);

export const createBanner = ({
	position,
	opening_screen,
	linked_products,
	file,
	start_date,
	expiration_date,
}: BannerApiRequest) => {
	const formData = new FormData();
	formData.append("position", position);
	opening_screen && formData.append("opening_screen", opening_screen);
	forEach(linked_products, (p: string) =>
		formData.append("linked_products", p),
	);
	formData.append("file", file);
	formData.append("start_date", start_date.toISOString());
	formData.append("expiration_date", expiration_date.toISOString());
	return request<any>("POST", BannerEndpoint, formData);
};

export const deleteBanner = (id: string) =>
	request<any>("DELETE", BannerEndpoint + `/${id}`);
